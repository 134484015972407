
import { defineComponent, PropType } from 'vue';
import { SRPListing } from '../types';
import { isVehicleSold, toCurrency } from '../utils';
import { useAuctionVDP, useCurrentNegotiationOffer, useGetArbitrationDetails, useHandleStatusChange, useIncrementViewCount, useSingleAuctionListener, useUpdateAuctionListingCardOnRdbChange, useUpdateWatchlistStatus, useUserRole } from '../composables';
import { pick } from 'lodash';

import AppButton from '../components/AppButton.vue';
import AppListingDetails from '../components/AppListingDetails.vue';
import AuctionStatBids from '../components/AuctionStatBids.vue';
import AuctionStatViews from '../components/AuctionStatViews.vue';
import BiddingButtons from '../components/Buttons/BiddingButtons.vue';
import CountdownTimer from '../components/CountdownTimer.vue';
import TheCheckoutButton from '../components/Buttons/TheCheckoutButton.vue';
import TheListingDetailsListingStats from '../components/TheListingDetailsListingStats.vue';
import TheListingWatchlistButton from '../components/TheListingWatchlistButton.vue';
import TheNegotiateButtonAuction from '../components/TheNegotiateButtonAuction.vue';
import TheOpenArbitrationClaimButton from '../components/TheOpenArbitrationClaimButton.vue';
import ViewOrderSummaryButton from '../components/Buttons/ViewOrderSummaryButton.vue';

export default defineComponent({
    name: 'AppListingDetailsBuyer',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
    },
    components: {
        AppButton,
        AppListingDetails,
        AuctionStatBids,
        AuctionStatViews,
        BiddingButtons,
        CountdownTimer,
        TheCheckoutButton,
        TheListingDetailsListingStats,
        TheListingWatchlistButton,
        TheNegotiateButtonAuction,
        TheOpenArbitrationClaimButton,
        ViewOrderSummaryButton,
    },
    emits: ['watching'],
    setup(props, context) {
        useIncrementViewCount(props.listing.id);
        
        const { 
            vehiclePhotos,
            loadingVehiclePhotos,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            bidHistory,
            loadingBidHistory,
            fetchBidHistoryOnRdbChange,
            order,
            loadingOrder,
            titleDelivery,
            vehicleDelivery,
            purchasedVehicle,
            fetchOrder,
        } = useAuctionVDP({ 
            vehicleListingId: props.listing.id,
            vehicleDetails: {
                year: props.listing.year.toString(),
                make: props.listing.brand,
                ...pick(props.listing, ['model', 'trim', 'vin']),
            }
        });

        const { highestBid, updateAuction } = useUpdateAuctionListingCardOnRdbChange();
        useSingleAuctionListener(props.listing.id!, (snapshot) => {
            updateAuction(snapshot);
            fetchBidHistoryOnRdbChange(snapshot);
            getUpdatedNegotiationOffer(props.listing.id);
        });

        useHandleStatusChange({
            rdbListener: highestBid,
            onStatusChange: (newStatus, oldStatus) => {
                if (isVehicleSold(newStatus) && !isVehicleSold(oldStatus)) {
                    fetchOrder(data => order.value = data);
                }
            }
        });

        const { updateWatchlistStatus } = useUpdateWatchlistStatus(props.listing.id, context);

        const { 
            loadingArbitrationDetails,
            arbitrationDetails,
        } = useGetArbitrationDetails(props.listing.id);

        const {
            negotiationOffer,
            loadingNegotiationOffer,
            getUpdatedNegotiationOffer,
        } = useCurrentNegotiationOffer();

        return {
            toCurrency,
            isVehicleSold,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            bidHistory,
            loadingBidHistory,
            vehiclePhotos,
            loadingVehiclePhotos,
            order,
            loadingOrder,
            titleDelivery,
            vehicleDelivery,
            highestBid,
            purchasedVehicle,
            updateWatchlistStatus,
            arbitrationDetails,
            negotiationOffer,
            loadingNegotiationOffer,
        }
    },
});
