var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.titleStatusFormatted || _vm.deliveryStatusFormatted)?_c('div',{staticClass:"flex-row align-center"},[(_vm.titleStatusFormatted)?_c('AppStatusIcon',_vm._b({},'AppStatusIcon',{
            tooltip: !_vm.showLabels ? _vm.titleStatusFormatted.label : undefined,
            type: _vm.titleStatusFormatted.type,
            class: _vm.titleStatusFormatted.class,
            icon: 'clipboard-outline',
            ..._vm.$attrs,
        },false)):_vm._e(),(_vm.showLabels && _vm.titleStatusFormatted)?_c('a',{staticClass:"mr-2 hover-underline",class:_vm.titleStatusFormatted.class,on:{"click":function($event){$event.stopPropagation();return _vm.openTrackingLink('title', _vm.titleTrackingLink)}}},[_vm._v(" "+_vm._s(_vm.titleStatusFormatted.label)+" ")]):_vm._e(),(_vm.deliveryStatusFormatted)?_c('AppStatusIcon',_vm._b({},'AppStatusIcon',{
            tooltip: !_vm.showLabels ? _vm.deliveryStatusFormatted.label : undefined,
            type: _vm.deliveryStatusFormatted.type,
            class: _vm.deliveryStatusFormatted.class,
            icon: 'truck-fast-outline',
            ..._vm.$attrs,
        },false)):_vm._e(),(_vm.showLabels && _vm.deliveryStatusFormatted)?_c('a',{staticClass:"mr-2 hover-underline",class:_vm.deliveryStatusFormatted.class,on:{"click":function($event){$event.stopPropagation();return _vm.openTrackingLink('vehicle', _vm.deliveryTrackingLink)}}},[_vm._v(" "+_vm._s(_vm.deliveryStatusFormatted.label)+" ")]):_vm._e()],1):_c('div',[_c('span',[_vm._v("Loading...")])])
}
var staticRenderFns = []

export { render, staticRenderFns }