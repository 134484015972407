import { GET, POST } from '@/api';
import { 
    APIConfig,
} from '@/types';
import { 
    applyAPIConfigOnSuccess, 
    applyAPIConfigOnError,
    openToast,
} from '@/utils';

export async function getOrderByVehicleListingId(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/vehicles/order/${vehicleListingId}`, undefined, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getTitleTrackingLink(vehicleListingId: number, config: APIConfig={}): Promise<string | undefined> {
    return await GET(`/orders/getTitleTrackingLink/${vehicleListingId}`, undefined, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getVehicleDeliveryTrackingLink(vehicleListingId: number, config: APIConfig={}): Promise<string | undefined> {
    return await GET(`/orders/getDeliveryTrackingLink/${vehicleListingId}`, undefined, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function updateTitleTrackingLink(payload: { vehicleListingId: number, trackingLink: string }, config: APIConfig={}) {
    return await POST(`/orders/updateTitleTrackingInfo`, payload, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            openToast('is-success', 'Tracking link updated!');
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openToast('is-danger', 'Error updating tracking link');
        });
}

export async function updateVehicleDeliveryTrackingLink(payload: { vehicleListingId: number, trackingLink: string, }, config: APIConfig={}) {
    return await POST(`/orders/updateDeliveryTrackingInfo`, payload, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            openToast('is-success', 'Tracking link updated!');
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openToast('is-danger', 'Error updating tracking link');
        });
}