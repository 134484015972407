import { didSellerAcceptTermsAndConditions, getAdminDashboardListingsByStatus, getAdminVehicleDetailsDTO, getAuctionDetails, getPlaceByVehicleListingId, getVehicleDetails, getVehicleSellerBasicDetails, getVehicleHighestBidderBasicDetails, getAdminMarketplaceDashboardListings, getMarketplaceEmployees, getHighestMarketplaceOffererDetails, getMarketplaceAutocompleteOptionsForSellerOrBuyerName, getMarketplaceAutocompleteOptionsForStoreName,  } from '@/api';
import { calculateBuyerOfferPercentOfMmr, cancelPreviousRequest, customSortTableByDate, formatPhoneNumber, getInspectorPersonIdByVehicleListingId, getMarketplaceSearchModifiers, getModifierSchema, isMarketplaceStatus, isUserDsr, openAssignInspectorModal, openEditListingLocationModal, openEditVehicleModal, openModal, openToast, toCurrency, toCurrencyShowDashesIfNull, updateUrlParams } from '@/utils';
import { getMarketplaceListingDetails } from '@/api/listings';
import { AdminDashboardDTO, AdminListingOfferDTO, AdminListingTableAction, AdminListingTableDTO, AdminMarketplaceTableDTO, APIConfig, CarmigoEmployeeDTO, FilterFieldOptionsSchema, HighestBidRefRecord, HighestBidRefSnapshot, MarketplaceDealerMarketDTO, SortingDirection, VehicleStatus, VehicleStatusGroup } from '@/types';
import { computed, ComputedRef, reactive, Ref, ref, watch } from 'vue';
import { concat, pick, uniqBy } from 'lodash';
import { useLoggedInUser } from './user';
import { useRoute } from 'vue-router/composables';
import { useCancelToken } from './fetch';
import { CancelTokenSource } from 'axios';
import { getMarketplaceListingId } from '@/api';
import store from '@/vuex';
import Vue from 'vue';

import ScheduleAuctionModal from '../components/ScheduleAuctionModal.vue';
import SelectSellFeeModal from '../components/Modals/SelectSellFeeModal.vue';
import TheNegotiationModalAdmin from '@/components/TheNegotiationModalAdmin.vue';

export function useEditListing({ vehicleListingId, emit }: {
    vehicleListingId: number,
    emit: (event: 'assignInspector', ...args: any[]) => void,
}) {
    const loadingVehicleDetails = ref(false);

    async function getVehicleDetailsAndOpenEditVehicleModal() {
        loadingVehicleDetails.value = true;
        let vehicleDetails = await getAdminVehicleDetailsDTO(vehicleListingId, {
            onSuccess: () => loadingVehicleDetails.value = false,
            onError: () => loadingVehicleDetails.value = false,
        });
        openEditVehicleModal(vehicleListingId, {
            ...vehicleDetails,
            year: vehicleDetails.year.toString(),
            condition: vehicleDetails.vehicleCondition,
        });
    }

    const loadingVehicleLocation = ref(false);
    async function getVehicleLocationAndOpenEditLocationModal() {
        loadingVehicleLocation.value = true;
        let { postalCode, city, state } = await getPlaceByVehicleListingId(vehicleListingId, {
            onSuccess: () => loadingVehicleLocation.value = false,
            onError: () => loadingVehicleLocation.value = false,
        });
        openEditListingLocationModal(vehicleListingId, {
            zipCode: postalCode,
            city,
            state
        });
    }

    function openAssignInspectorModalWithEmit(inspectorPersonId?: number | null) {
        openAssignInspectorModal(vehicleListingId, inspectorPersonId, { emit });
    }

    const loadingInspectorPersonId = ref(false);
    async function getAssignedInspectorAndOpenAssignInspectorModal() {
        loadingInspectorPersonId.value = true;
        let inspectorPersonId = await getInspectorPersonIdByVehicleListingId(vehicleListingId, {
            onSuccess: () => loadingInspectorPersonId.value = false,
            onError: () => loadingInspectorPersonId.value = false,
        });
        openAssignInspectorModalWithEmit(inspectorPersonId);
    }

    return {
        loadingInspectorPersonId,
        getAssignedInspectorAndOpenAssignInspectorModal,
        openAssignInspectorModalWithEmit,
        getVehicleLocationAndOpenEditLocationModal,
        loadingVehicleLocation,
        getVehicleDetailsAndOpenEditVehicleModal,
        loadingVehicleDetails,
    }
}

export function usePreAuctionFunctions({ vehicleListingId }: {
    vehicleListingId: number,
}) {
    const loadingCheckTermsAndConditions = ref(false);
    const sellerAcceptedTermsAndConditions: Ref<boolean | undefined> = ref(undefined);
    async function checkTermsAndConditionsAccepted() {
        loadingCheckTermsAndConditions.value = true;
        sellerAcceptedTermsAndConditions.value = await didSellerAcceptTermsAndConditions(vehicleListingId, {
            onSuccess: () => loadingCheckTermsAndConditions.value = false,
            onError: () => loadingCheckTermsAndConditions.value = false,
        });
    }

    function openSelectSellFeeModal({ sellerStoreId, onSuccess=() => {}, onClose=() => {}}: {
        sellerStoreId?: number,
        onSuccess?: () => void,
        onClose?: (event: { isCancelled?: boolean }) => void,
    }) {
        openModal({
            component: SelectSellFeeModal,
            props: {
                vehicleListingId,
                sellerStoreId,
            },
            events: {
                success: onSuccess,
                close: onClose,
            }
        });
    }


    function openScheduleAuctionModal({ onSuccess=() => {} }: {
        onSuccess: () => void,
    }) {
        openModal({
            component: ScheduleAuctionModal,
            props: {
                vehicleListingId,
                hasMobileView: true,
                autofillEndTime: true,
            },
            events: {
                success: onSuccess,
                close: onSuccess,
            }
        });
    }

    async function selectSellFeeAndScheduleAuction({
        sellerStoreId, vehicleStatus, onError=() => {}, onSellFeeSelected=() => {}, onSellFeeClose=() => {}, onAuctionScheduled=() => {},
    }: {
        sellerStoreId?: number,
        vehicleStatus: VehicleStatus,
        onError: (error: string) => void,
        onSellFeeClose: (event?: { isCancelled?: boolean }) => void,
        onSellFeeSelected: () => void,
        onAuctionScheduled: () => void,
    }) {
        // if status can't schedule auctions, return early
        let canScheduleAuctionStatuses = ['InspectionScheduled', 'Inspected', 'MarketplacePending'];
        if (!canScheduleAuctionStatuses.includes(vehicleStatus)) {
            onError('status');
            openToast('is-danger', `Status ${vehicleStatus} cannot schedule auctions`);
            return;
        }
        const isMarketplaceListing: boolean = isMarketplaceStatus(vehicleStatus);

        // if not marketplace, check T&C
        if (!isMarketplaceListing) {
            await checkTermsAndConditionsAccepted();

            // if T&C not accepted, return early
            if (!sellerAcceptedTermsAndConditions.value) {
                onError('termsAndConditionsNotAccepted');
                openToast('is-danger', 'Seller has not accepted Terms & Conditions');
                return;
            }
        }

        // select sell fee
        openSelectSellFeeModal({
            sellerStoreId,
            onSuccess: () => {
                // if marketplace, return early
                if (isMarketplaceListing || isUserDsr()) {
                    onSellFeeSelected();
                    return;
                }

                // schedule auction
                openScheduleAuctionModal({
                    onSuccess: onAuctionScheduled, // do something after scheduling
                });
            },
            onClose: onSellFeeClose,
        });
    }
    return {
        selectSellFeeAndScheduleAuction,
    }
}

export function useVehicleDetailsCards({ vehicleListingId, marketplaceListingId }: { 
    vehicleListingId: number,
    marketplaceListingId?: number,
}) {
    const loadingVehicleBasicDetails = ref(false);
    const vehicleBasicDetails = ref({
        id: '--',
        year: '--',
        make: '--',
        model: '--',
        trim: '--',
        vin: '--',
        mileage: '--',
    });

    async function getVehicleBasicDetails() {
        loadingVehicleBasicDetails.value = true;
        await getVehicleDetails(vehicleListingId, {
            onSuccess: (res) => {
                vehicleBasicDetails.value = pick(res, ['id', 'year', 'make', 'model', 'trim', 'vin', 'mileage']);
                vehicleBasicDetails.value.mileage = vehicleBasicDetails.value.mileage ? vehicleBasicDetails.value.mileage.toLocaleString() : '--';
                if (!vehicleBasicDetails.value.trim) {
                    vehicleBasicDetails.value.trim = '--';
                }
                loadingVehicleBasicDetails.value = false;
            }, 
            onError: () => loadingVehicleBasicDetails.value = false,
        });
    }

    const loadingAuctionDetails = ref(false);
    const auctionDetails = ref({
        highestBidderStore: '--',
        highestBidder: '--',
        reserve: '--',
        highestBid: '--',
        secondHighestBidderStore: '--',
        secondHighestBidder: '--',
        outTheDoorPrice: '--',
    });
    async function getVehicleAuctionDetails() {
        await getAuctionDetails(vehicleListingId, {
            onSuccess: (res) => {
                auctionDetails.value = {
                    highestBidderStore: res.highestBidder.storeName,
                    highestBidder: `${res.highestBidder.firstName} ${res.highestBidder.lastName}`, 
                    reserve: toCurrencyShowDashesIfNull(res.reserve),
                    highestBid: toCurrencyShowDashesIfNull(res.highestBid),
                    secondHighestBidderStore: res.secondHighestBidder?.storeName ?? '--', 
                    secondHighestBidder: res.secondHighestBidder ? `${res.secondHighestBidder.firstName} ${res.secondHighestBidder?.lastName}` : '--',
                    outTheDoorPrice: toCurrencyShowDashesIfNull(res.highestBidderOutTheDoorPrice),
                }
                loadingAuctionDetails.value = false;
            },
            onError: () => loadingAuctionDetails.value = false,
        });
    }
    

    const loadingSellerDetails = ref(false);
    const sellerDetails = ref({
        sellerType: '--',
        name: '--',
        phone: '--',
        email: '--', 
        location: '--',
        sellFee: '--',
    });
    async function getSellerDetails() {
        loadingSellerDetails.value = true;
        await getVehicleSellerBasicDetails(vehicleListingId, {
            onSuccess: (res) => {
                sellerDetails.value = {
                    sellerType: res.sellerType,
                    name: `${res.firstName} ${res.lastName}`, 
                    phone: formatPhoneNumber(res.phoneNumber) ?? '--',
                    email: res.email ?? '--',
                    location: res.address ? `${res.address.city}, ${res.address.state} ${res.address.zip}` : '--', 
                    sellFee: toCurrencyShowDashesIfNull(res.sellFee),
                }
                loadingSellerDetails.value = false;
            },
            onError: () => loadingSellerDetails.value = false,
        });
    }

    const loadingBuyerDetails = ref(false);
    const buyerDetails = ref({
        name: '--',
        phone: '--',
        email: '--',
        location: '--',
        buyFee: '--',
        transportFee: '--',
    });
    async function getBuyerDetails() {
        loadingBuyerDetails.value = true;

        function updateBuyerDetails(details: {
            firstName: string,
            lastName: string,
            phoneNumber?: string,
            email?: string, 
            location?: {
                city: string,
                state: string,
                zip: string,
            },
            buyFee?: number,
            transportCost?: number,
        }) {
            buyerDetails.value = {
                name: `${details.firstName} ${details.lastName}`, 
                phone: formatPhoneNumber(details.phoneNumber) ?? '--',
                email: details.email ?? '--',
                location: details.location ? `${details.location.city}, ${details.location.state} ${details.location.zip}` : '--',
                buyFee: toCurrencyShowDashesIfNull(details.buyFee),
                transportFee: toCurrencyShowDashesIfNull(details.transportCost),
            }
        }

        if (marketplaceListingId) {
            await getHighestMarketplaceOffererDetails(vehicleListingId, {
                onSuccess: res => {
                    updateBuyerDetails(res);
                    loadingBuyerDetails.value = false;
                },
                onError: () => loadingBuyerDetails.value = false,
            });
        } else {
            await getVehicleHighestBidderBasicDetails(vehicleListingId, {
                onSuccess: res => {
                    updateBuyerDetails(res);
                    loadingBuyerDetails.value = false;
                },
                onError: () => loadingBuyerDetails.value = false,
            });
        }
    }

    const loadingMarketplaceDetails = ref(false);
    const marketplaceDetails = reactive({
        'Marketplace Listing ID': '--',
        'Floor Price': '--',
        'Buy-It-Now Price': '--',
        'Highest Offer': '--',
        'Highest Offerer': '--',
        'View count': '--',
        'Offer count': '--',
    });
    const marketplaceDetailsRaw: Ref<MarketplaceDealerMarketDTO |undefined> = ref(undefined);
    async function getMarketplaceDetails(marketplaceListingId?: number) {
        if (!marketplaceListingId) {
            marketplaceListingId = await getMarketplaceListingId(vehicleListingId);
        }
        loadingMarketplaceDetails.value = true;
        await getMarketplaceListingDetails(marketplaceListingId, {
            onSuccess: (listing) => {
                marketplaceDetailsRaw.value = listing;
                marketplaceDetails['Marketplace Listing ID'] = listing.marketplaceListingId;
                marketplaceDetails['Floor Price'] = listing.reservePrice ? toCurrency(listing.reservePrice) : '--';
                marketplaceDetails['Buy-It-Now Price'] = listing.buyItNowPrice ? toCurrency(listing.buyItNowPrice) : '--';
                marketplaceDetails['Highest Offer'] = listing.highestOfferAmount ? toCurrency(listing.highestOfferAmount) : '--';
                marketplaceDetails['Highest Offerer'] = listing.highestOfferStoreName ?? '--';
                marketplaceDetails['View count'] = listing.numViews;
                marketplaceDetails['Offer count'] = listing.numOffers;
                loadingMarketplaceDetails.value = false;
            },
            onError: () => loadingMarketplaceDetails.value = false,
        });
    }

    const loadingSecondChanceDetails = ref(false);
    const secondChanceDetails = reactive({
        'Marketplace Listing ID': '--',
        'Reserve Price': '--',
        'Highest Offer': '--',
        'Highest Offerer': '--',
        'View count': '--',
        'Offer count': '--',
    });
    const secondChanceDetailsRaw = ref(undefined);
    async function getSecondChanceDetails(marketplaceListingId: number) {
        loadingSecondChanceDetails.value = true;
        await getMarketplaceListingDetails(marketplaceListingId, {
            onSuccess: (listing) => {
                secondChanceDetailsRaw.value = listing;
                secondChanceDetails['Marketplace Listing ID'] = listing.marketplaceListingId;
                secondChanceDetails['Reserve Price'] = listing.reservePrice ? toCurrency(listing.reservePrice) : '--';
                secondChanceDetails['Highest Offer'] = listing.highestOfferAmount ? toCurrency(listing.highestOfferAmount) : '--';
                secondChanceDetails['Highest Offerer'] = listing.highestOfferStoreName ?? '--';
                secondChanceDetails['View count'] = listing.numViews;
                secondChanceDetails['Offer count'] = listing.numOffers;
                loadingSecondChanceDetails.value = false;
            },
            onError: () => loadingSecondChanceDetails.value = false,
        });
    }

    return {
        getVehicleBasicDetails,
        vehicleBasicDetails,
        loadingVehicleBasicDetails,

        getVehicleAuctionDetails,
        auctionDetails,
        loadingAuctionDetails,

        getSellerDetails,
        sellerDetails,
        loadingSellerDetails,

        getBuyerDetails,
        buyerDetails,
        loadingBuyerDetails,

        getMarketplaceDetails,
        marketplaceDetails,
        marketplaceDetailsRaw,
        loadingMarketplaceDetails,

        getSecondChanceDetails,
        secondChanceDetails,
        secondChanceDetailsRaw,
        loadingSecondChanceDetails,
    }
}

export function useAdminDashboardTable({ status, sortBy='vehicleListing.id', sortByDirection='desc', paginationLimit=25, paginationOffset=0 }: {
    status: VehicleStatusGroup,
    sortBy?: string,
    sortByDirection?: SortingDirection,
    paginationLimit?: number,
    paginationOffset?: number,
}) {
    const loadingTableData = ref(false);
    const tableData: Ref<AdminDashboardDTO[]> = ref([]);
    const checkedRows: Ref<AdminDashboardDTO[]> = ref([]);
    const paginationAndSorting = reactive({
        paginationLimit,
        paginationOffset,
        orderBy: sortBy,
        orderByDirection: sortByDirection,
    });
    const canLoadMore: Ref<boolean> = ref(true);

    async function getTableData({ fromPagination }: { fromPagination?: boolean }={}) {
      loadingTableData.value = true;
      await getAdminDashboardListingsByStatus(status, paginationAndSorting, {
        onSuccess: (newListings) => {
            loadingTableData.value = false;

            tableData.value = fromPagination 
                ? tableData.value.concat(newListings)
                : newListings;

            paginationAndSorting.paginationOffset += newListings.length;
            canLoadMore.value = newListings.length >= paginationAndSorting.paginationLimit;
        },
        onError: () => loadingTableData.value = false,
      });
    }

    function getTableDataWithSorting(newSortBy: string, newSortByDirection: SortingDirection) {
        if (newSortBy !== paginationAndSorting.orderBy || newSortByDirection !== paginationAndSorting.orderByDirection) {
            canLoadMore.value = true;
            paginationAndSorting.paginationOffset = 0;
            paginationAndSorting.orderBy = newSortBy;
            paginationAndSorting.orderByDirection = newSortByDirection;
            getTableData();
        }
    }

    function updateAssignedInspector(vehicleListingId: number, inspectorPersonId: number) {
        let rowIdx = tableData.value.findIndex(row => parseInt(row.id) == vehicleListingId);
        if (rowIdx >= 0) {
            tableData.value[rowIdx].inspectorPersonId = inspectorPersonId;
        }
    }

    function removeListingsFromTable(vehicleListingIds: number[]) {
        vehicleListingIds.forEach(vehicleListingId => {
            let rowIdx = tableData.value.findIndex(row => parseInt(row.id) == vehicleListingId);
            if (rowIdx >= 0) {
                tableData.value.splice(rowIdx, 1);
            }
        });
    }

    function updateTableRowOnRealtimeUpdate(vehicleListingId: number, { newSnapshotValue }: HighestBidRefSnapshot) {
        let listing = tableData.value.find(row => row.id == vehicleListingId.toString());
        if (!listing) {
            return;
        }

        // Update auction dates if changed
        if (listing.auctionStartDate !== newSnapshotValue.auctionStart) {
            listing.auctionStartDate = newSnapshotValue.auctionStart;
        }
        if (listing.auctionEndDate !== newSnapshotValue.auctionEnd) {
            listing.auctionEndDate = newSnapshotValue.auctionEnd;
        }

        // Status change
        if (listing.status !== newSnapshotValue.status) {
            removeListingsFromTable([vehicleListingId]);
            openToast('is-success', `A ${listing.year} ${listing.make} ${listing.model} (ID: ${listing.id}) moved to ${newSnapshotValue.status} status`);
            return;
        }
    }

    return {
        checkedRows,
        getTableData,
        getTableDataWithSorting,
        tableData,
        loadingTableData,
        updateAssignedInspector,
        paginationAndSorting,
        canLoadMore,
        removeListingsFromTable,
        updateTableRowOnRealtimeUpdate,
    }
}

export function useAdminListingTableActions<T>({ tableData }: {
    tableData: Ref<(AdminListingTableDTO & T)[]>,
}) {
    const expandedRows: Ref<number[]> = ref([]); // vehicleListingIds

    // bulk table actions
    const selectedRows: Ref<(AdminListingTableDTO & T)[]> = ref([]);
    function updateTableData(updateType: AdminListingTableAction, vehicleListingId?: number) {
        if (vehicleListingId) { // single row update
            updateTableRowByVehicleListingId(updateType, vehicleListingId);
        } else { // bulk table update
            selectedRows.value.forEach(({ vehicleListingId: rowVehicleListingId }: (AdminListingTableDTO & T)) => updateTableRowByVehicleListingId(updateType, rowVehicleListingId));
        }
        selectedRows.value = [];
    }

    function updateTableRowByVehicleListingId(updateType: AdminListingTableAction, vehicleListingId: number) {
        let rowIdx: number = tableData.value.findIndex(row => row.vehicleListingId == vehicleListingId);
        if (rowIdx < 0) {
            return;
        }
        let updateRow = tableData.value[rowIdx];

        switch(updateType) {
            case 'isUpvoted':
                updateRow.isUpvoted = true;
                updateRow.numUpvotes = updateRow.numUpvotes ? updateRow.numUpvotes++ : 1;
                break;
            case 'markedNotSold':
            case 'reinspect':
            case 'orderCreated':
            case 'sentToSecondChance':
                tableData.value.splice(rowIdx, 1);
                openToast('is-success', 'Listing removed from table due to updated status');
                break;
        }
    }

    return {
        expandedRows,
        selectedRows,
        updateTableData,
    }
}

export function useAdminListingOfferTableNeedsAttention({ tableData, selectedEmployees, vehicleListingId }: {
    tableData: Ref<(AdminListingOfferDTO & AdminListingTableDTO)[]>,
    selectedEmployees?: Ref<('View All' | CarmigoEmployeeDTO)[]>,
    vehicleListingId?: number, // if using a single listing 
}) {
    const { loggedInUserPersonId } = useLoggedInUser();

    const showNeedsAttentionOnly: Ref<boolean> = ref(false);
    const needsAttentionTableData = computed(() => {
        return tableData.value.filter(({ vehicleListingId }) => {
            return hasNoteVehicleListingIds.value.includes(vehicleListingId) 
                || hasSellerOfferVehicleListingIds.value.includes(vehicleListingId)
                || hasBuyerOfferVehicleListingIds.value.includes(vehicleListingId);
        });
    });

    function openNeedsAttentionToast() {
        if (showNeedsAttentionOnly.value && needsAttentionTableData.value.length) {
            openToast('is-success', `Displaying ${needsAttentionTableData.value.length} cars that need attention`);
        } 
    }

    // if mostRecentNote type is 'admin' and the senderPersonId is not me
    const hasNoteVehicleListingIds: ComputedRef<number[]> = computed(() => {
        let vehicleListingIds: number[] = [];
        tableData.value.forEach(row => {
            if (!row.mostRecentNote) {
                return;
            }
            if (row.mostRecentNote.noteType.toLowerCase() == 'admin' && row.mostRecentNote.senderPersonId !== loggedInUserPersonId.value) {
                vehicleListingIds.push(row.vehicleListingId);
            }
        });
        return vehicleListingIds;
    });

    // if selected user corresponding with this row has role 'am' and mostRecentOfferType is 'seller'
    // Used for the 'Seller At' icon
    const hasSellerOfferVehicleListingIds: ComputedRef<number[]> = computed(() => {
        let vehicleListingIds: number[] = [];

        if (!selectedEmployees?.value || selectedEmployees.value.includes('View All')) {
            return [];
        }

        tableData.value.forEach(row => {
            if (!row.mostRecentOfferType) {
                return;
            }
            const isSelectedAccountManager = (selectedEmployees.value as CarmigoEmployeeDTO[]).some(employee => employee.role.includes('am') && employee.personId == row.accountManager.personId);
            if (row.mostRecentOfferType.toLowerCase() == 'seller' && isSelectedAccountManager) {
                vehicleListingIds.push(row.vehicleListingId);
            }
        });
        return vehicleListingIds;
    });


    // if selected user corresponding with this row has role 'dsr' and mostRecentOfferType is 'buyer'    
    // Used for the 'Buyer At' icon
    const hasBuyerOfferVehicleListingIds: ComputedRef<number[]> = computed(() => {
        let vehicleListingIds: number[] = [];
        if (!selectedEmployees?.value || selectedEmployees.value.includes('View All')) {
            return [];
        }

        tableData.value.forEach(row => {
            if (!row.mostRecentOfferType) {
                return;
            }
            const isSelectedDsr = (selectedEmployees.value as CarmigoEmployeeDTO[]).some(employee => employee.role.includes('dsr') && employee.personId == row.dealerSalesRepresentative.personId);
            if (row.mostRecentOfferType.toLowerCase() == 'buyer' && isSelectedDsr) {
                vehicleListingIds.push(row.vehicleListingId);
            }
        });
        
        return vehicleListingIds;
    });

    const hasOfferVehicleListingIds = computed(() => {
        return concat(hasBuyerOfferVehicleListingIds.value, hasSellerOfferVehicleListingIds.value);
    });

    const hasNote = computed(() => {
        return vehicleListingId 
            ? hasNoteVehicleListingIds.value.includes(vehicleListingId)
            : false;
    });

    const hasSellerOffer = computed(() => {
        return vehicleListingId 
            ? hasSellerOfferVehicleListingIds.value.includes(vehicleListingId)
            : false;
    });

    const hasBuyerOffer = computed(() => {
        return vehicleListingId 
            ? hasBuyerOfferVehicleListingIds.value.includes(vehicleListingId)
            : false;
    });

    return {
        showNeedsAttentionOnly,
        needsAttentionTableData,
        openNeedsAttentionToast,
        hasNoteVehicleListingIds,
        hasSellerOfferVehicleListingIds,
        hasBuyerOfferVehicleListingIds,
        hasOfferVehicleListingIds,
        hasNote,
        hasSellerOffer,
        hasBuyerOffer,
    }
}

export function useSelectEmployeesDropdown({ tableData, loadingTableData, selectedRows, getTableDataCallback, convertToModifierSchemaCallback, clearTableSearchCallback, cancelToken }: {
    tableData: Ref<any[]>,
    loadingTableData: Ref<boolean>,
    getTableDataCallback: (modifiers?: FilterFieldOptionsSchema) => Promise<void>,
    convertToModifierSchemaCallback: (employees: CarmigoEmployeeDTO[]) => FilterFieldOptionsSchema | undefined,
    cancelToken?: Ref<CancelTokenSource | undefined>,
    selectedRows?: Ref<any[]>,
    clearTableSearchCallback?: () => void,
}) {
    const selectedEmployees: Ref<('View All' | CarmigoEmployeeDTO)[]> = ref([]);
    const selectedEmployeesKey: Ref<number> = ref(0);
    const route = useRoute();
    let preventDefaultClearEmployeeActions = false;

    watch(() => selectedEmployees.value, async(newValue) => {
        // clear selectedRows
        if (selectedRows) {
            selectedRows.value = [];
        }
        // if 'View All' is selected, getTableData with no modifiers & return early
        if (newValue.includes('View All')) {
            return await getTableDataCallback().then(res => selectedEmployeesKey.value++);
        }

        // if no employes are selected, clear table data and cancel previous requests & return early
        if (!selectedEmployees.value.length) {
            if (cancelToken?.value) {
                cancelToken.value = cancelPreviousRequest(cancelToken.value, 'Previous request to fetch table data cancelled');
            }

            if (loadingTableData) {
                loadingTableData.value = false;
            }
            tableData.value = [];
            return;
        }

        // update query params
        updateUrlParams({
            route, 
            newQueryParams: {
                employees: (selectedEmployees.value as CarmigoEmployeeDTO[]).map(value => value?.personId.toString()),
            },
            maintainAllParams: true,
        });


        // clear table search if it exists
        if (clearTableSearchCallback) {
            clearTableSearchCallback();
        }

        // get table data with employee modifiers
        let modifiers = undefined;
        if (selectedEmployees.value.length) {
            modifiers = convertToModifierSchemaCallback(selectedEmployees.value as CarmigoEmployeeDTO[]);
        }
        await getTableDataCallback(modifiers).then(res => selectedEmployeesKey.value++);
    }, { deep: true });

    function removeSelectedEmployee(personId: number) {
        const idx = selectedEmployees.value.findIndex(value => value !== 'View All' && value.personId == personId);
        if (idx >= 0) {
            selectedEmployees.value.splice(idx, 1);
        }
    }

    function clearSelectedEmployees() {
        selectedEmployees.value = [];
    }

    return {
        selectedEmployees,
        selectedEmployeesKey,
        removeSelectedEmployee,
        clearSelectedEmployees,
    }
}

export function useAdminListingTableSearchAndFilters({ defaultSearchField, getTableDataCallback, convertSearchToModifierSchemaCallback, getAutocompleteOptionsCallback } : {
    defaultSearchField: string,
    getTableDataCallback: (modifiers?: FilterFieldOptionsSchema) => Promise<void>,
    convertSearchToModifierSchemaCallback: (searchByField: string, searchByValue?: string | number | number[] | string[]) => FilterFieldOptionsSchema | undefined,
    getAutocompleteOptionsCallback: (searchByField: string, value: string, config?: APIConfig) => Promise<({ result: string, count: number })[]>,
}) {
    const isDisplayingSearchResults: Ref<boolean> = ref(false);
    const searchBy: { field: string, value: string | undefined } = reactive({ field: defaultSearchField, value: undefined });
    const searchKey: Ref<number> = ref(0);
    watch(() => searchBy.field, () => searchBy.value = undefined); // clear search value when search field is changed

    async function getTableDataFromSearch(searchValue?: { field: string, value: string }) {
        searchBy.value = searchValue?.value ? searchValue.value.trim() : undefined;
        let modifiers = convertSearchToModifierSchemaCallback(searchBy.field, searchBy.value);
        await getTableDataCallback(modifiers).then(() => isDisplayingSearchResults.value = true);
    }

    function clearTableSearch() {
        if (searchBy.value) {
            searchBy.value = undefined;
            searchKey.value++;
        }
    }

    // search autocomplete / typeaheads
    const autocompleteValue: Ref<{ name: string | undefined }> = ref({ name: undefined });
    const searchAutocompleteOptions: Ref<{ result: string, count: number }[]> = ref([]);
    const loadingSearchAutocompleteOptions: Ref<boolean> = ref(false);

    async function getSearchAutocompleteOptions(value: string) {
        if (!value || typeof value !== 'string') {
            return;
        }
        autocompleteValue.value.name = value;
        loadingSearchAutocompleteOptions.value = true;
        getAutocompleteOptionsCallback(searchBy.field, value, {
            onSuccess: (value) => {
                searchAutocompleteOptions.value = value;
                loadingSearchAutocompleteOptions.value = false
            },
            onError: () => loadingSearchAutocompleteOptions.value = false,
        });
    }

    return {
        isDisplayingSearchResults,
        getTableDataFromSearch,
        clearTableSearch,
        searchBy,
        searchKey,

        // autocomplete search 
        autocompleteValue,
        searchAutocompleteOptions,
        loadingSearchAutocompleteOptions,
        getSearchAutocompleteOptions,
    }
}

export function useFetchMarketplaceEmployees(isSecondChance: boolean=false) {
    const employeeOptions: Ref<CarmigoEmployeeDTO[]> = ref([]);
    const loadingEmployees: Ref<boolean> = ref(false);

    async function getEmployeesForMarketplaceOffers() {
        let employeesFromStorage = isSecondChance ? store.state.secondChanceEmployees : store.state.marketplaceEmployees;
        if (employeesFromStorage?.length) {
            employeeOptions.value = employeesFromStorage;
            return;
        }
        loadingEmployees.value = true;
        await getMarketplaceEmployees(isSecondChance, {
            onSuccess: (res) => {
                loadingEmployees.value = false;
                employeeOptions.value = uniqBy(res, 'personId') as unknown as CarmigoEmployeeDTO[];
            },
            onError: () => loadingEmployees.value = false,
        });
    }

    return {
        employeeOptions,
        loadingEmployees,
        getEmployeesForMarketplaceOffers,
    }
}

export function useAdminMarketplaceDashboardTable({ isSecondChance }: { isSecondChance?: boolean }={}) {
    const { cancelToken, cancelPreviousRequest } = useCancelToken();

    const tableData: Ref<AdminMarketplaceTableDTO[]> = ref([]);
    const loadingTableData: Ref<boolean> = ref(false);

    async function getMarketplaceListingsWithOffers(modifiers: FilterFieldOptionsSchema={}, config: APIConfig={}, isUpdatingRow?: boolean): Promise<void> {
        let finalModifiers = modifiers;
        if (isSecondChance !== undefined) {
            let secondChanceModifier = getMarketplaceSearchModifiers('isSecondChance', isSecondChance);
            finalModifiers = {
                ...modifiers,
                modifiers: {
                    filters: [
                        ...(modifiers.modifiers?.filters ?? []),
                        ...(secondChanceModifier?.modifiers?.filters ?? []),
                    ],
                },
            }
        }
        cancelPreviousRequest();
        if (!isUpdatingRow) {
            loadingTableData.value = true;
        }
        await getAdminMarketplaceDashboardListings(finalModifiers, {
            onSuccess: (res) => {
                loadingTableData.value = false;
                tableData.value = res;
            },
            onError: () => loadingTableData.value = false,
            cancelToken: cancelToken.value?.token,
            skipErrorOnCancelRequest: true,
            ...config,
        });
    }

    function sortByMarketplaceOfferExpirationDate(a: AdminMarketplaceTableDTO, b: AdminMarketplaceTableDTO, isAsc: boolean) {
        return customSortTableByDate(a.marketplaceOfferExpirationDate, b.marketplaceOfferExpirationDate, isAsc);
    }

    function openAdminMarketplaceOfferModal(user: 'buyer' | 'seller', listing?: AdminMarketplaceTableDTO) {
        if (!listing) {
            return;
        }
        openModal({
            component: TheNegotiationModalAdmin,
            props: {
                ...pick(listing, ['vehicleListingId', 'marketplaceListingId', 'isSecondChance', 'marketplaceOfferId', 'vin']),
                sellerOfferAmount: listing.lowestSellerOfferAmount ?? listing.reservePrice,
                buyerOfferAmount: listing.highestBuyerOfferAmount,
                sellerPersonId: listing.seller.personId,
                buyerPersonId: listing.buyer?.personId,
                storeId: listing.buyer?.storeId,
                negotiatingUser: user,
            },
            events: {
                submitted: (updatedOffer: { buyerOfferAmount: number, sellerOfferAmount: number }) => {
                    const rowIdx = tableData.value.findIndex(row => row.vehicleListingId == listing.vehicleListingId);
                    if (rowIdx >= 0) {
                        const row = tableData.value[rowIdx];
                        tableData.value[rowIdx].highestBuyerOfferAmount = updatedOffer.buyerOfferAmount;
                        tableData.value[rowIdx].lowestSellerOfferAmount = updatedOffer.sellerOfferAmount;
                        tableData.value[rowIdx].mostRecentOfferType = user;
                        tableData.value[rowIdx].percentMmr = calculateBuyerOfferPercentOfMmr(row.mmr, row.highestBuyerOfferAmount);
                    }
                }
            }
        })
    }

    const rdbUpdateKey: Ref<number> = ref(0);
    async function updateTableRow(vehicleListingId: number) {
        let rowIdx = tableData.value.findIndex(row => row.vehicleListingId == vehicleListingId);
        if (rowIdx >= 0) {
            cancelPreviousRequest();
            const modifier = getModifierSchema('vehicleListingId', vehicleListingId);
            await getMarketplaceListingsWithOffers(modifier, {
                onSuccess: (res) => {
                    if (res?.length) {
                        Vue.set(tableData.value, rowIdx, res[0]);
                        rdbUpdateKey.value++;
                    }
                },
            }, true);
        }
    }

    function updateTableRowOnRealtimeUpdate(vehicleListingId: number, { isInitialSnapshot, newSnapshotValue, oldSnapshotValue }: HighestBidRefSnapshot) {
        if (isInitialSnapshot || !newSnapshotValue) {
            return;
        }
        if ((oldSnapshotValue as HighestBidRefRecord)?.note !== (newSnapshotValue as HighestBidRefRecord).note) {
            updateTableRow(vehicleListingId);
        }
    }

    async function getMarketplaceDashboardAutocompleteOptions(searchByField: string, value: string, config: APIConfig={}): Promise<{ result: string, count: number }[]> {
        switch (searchByField) {
            case 'personName':
                return await getMarketplaceAutocompleteOptionsForSellerOrBuyerName(value, isSecondChance ?? false, config);
            case 'storeName':
                return await getMarketplaceAutocompleteOptionsForStoreName(value, isSecondChance ?? false, config);
            default:
                return [];
        }
    }

    return {
        tableData,
        loadingTableData,
        cancelToken,
        getMarketplaceListingsWithOffers,
        openAdminMarketplaceOfferModal,
        sortByMarketplaceOfferExpirationDate,
        updateTableRowOnRealtimeUpdate,
        rdbUpdateKey,
        getMarketplaceDashboardAutocompleteOptions,
    }
}