
import { defineComponent } from 'vue';
import { useNegotiationModal } from '../composables';
import { negotiationModalProps, SubmitNegotiationDTO } from '../types';
import { capitalize, toCurrency } from '../utils';
import { submitNegotiation } from '@/api';
import { pick } from 'lodash';

import AppCheckboxInput from './AppCheckboxInput.vue';
import TheNegotiationModal from './TheNegotiationModal.vue';

export default defineComponent({
    name: 'TheNegotiationModalAuction',
    props: {
        ...negotiationModalProps,
        buyerPersonId: {
            type: Number,
            required: true,
        },
        sellerPersonId: {
            type: Number,
            required: true,
        },
    },
    components: {
        AppCheckboxInput,
        TheNegotiationModal,
    },
    emits: ['close', 'submitted'],
    setup(props, context) {
        const {
            negotiatingUserOfferAmount,
            otherUserOfferAmount,
            otherUser,
            submitCounterOffer,
            acceptNegotiationOffer,
            rejectNegotiationOffer,
            loadingSubmitCounterOffer,
            loadingAcceptOffer,
            loadingRejectOffer,
            sendNotifications,
        } = useNegotiationModal({
            vehicleListingId: props.vehicleListingId!,
            negotiatingUser: props.negotiatingUser!,
            buyerOfferAmount: props.buyerOfferAmount!,
            sellerOfferAmount: props.sellerOfferAmount!,
            buyerPersonId: props.buyerPersonId,
            sellerPersonId: props.sellerPersonId,
            submitNegotiationCallback: async (negotiation: SubmitNegotiationDTO) => {
                await submitNegotiation(props.vehicleListingId!, {
                    ...pick(negotiation, ['highestBuyerOffer', 'lowestSellerOffer', 'disableNotifications']),
                    buyerId: negotiation.buyerPersonId.toString(),
                    sellerId: negotiation.sellerPersonId.toString(),
                    onBuyer: negotiation.negotiatingUser == 'buyer' ? false : true,
                    status: negotiation.negotiationStatus,
                });
            },
            context,
        });

        return {
            capitalize,
            toCurrency,
            sendNotifications,
            submitCounterOffer,
            acceptNegotiationOffer,
            rejectNegotiationOffer,
            loadingAcceptOffer,
            loadingRejectOffer,
            loadingSubmitCounterOffer,
            negotiatingUserOfferAmount,
            otherUser,
            otherUserOfferAmount,
        }
    },
});
