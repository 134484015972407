
import { defineComponent, ref } from 'vue';
import { createAdminNote, submitNegotiation } from '../api';
import { useNegotiationModal } from '../composables';
import { negotiationModalWithMessagesProps, SubmitNegotiationDTO } from '../types';
import { capitalize, submitMarketplaceNegotiation, toCurrency } from '../utils';
import { pick } from 'lodash';

import TheNegotiationModalWithMessages from './TheNegotiationModalWithMessages.vue';

export default defineComponent({
    name: 'TheNegotiationModalAdmin',
    props: {
        ...negotiationModalWithMessagesProps,
        buyerPersonId: {
            type: Number,
            required: true,
        },
        sellerPersonId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
        marketplaceOfferId: {
            type: Number,
            required: false, // required if marketplaceListingId is provided
        },
        storeId: {
            type: Number,
            required: false, // required if marketplaceListingId is provided
        },
    },
    components: {
        TheNegotiationModalWithMessages,
    },
    emits: ['close', 'submitted'],
    setup(props, context) {
        const negotiationNote = ref(undefined);

        const {
            negotiatingUserOfferAmount,
            otherUserOfferAmount,
            otherUser,
            offererPersonId,
            loadingSubmitCounterOffer,
            submitCounterOffer,
        } = useNegotiationModal({
            vehicleListingId: props.vehicleListingId!,
            negotiatingUser: props.negotiatingUser!,
            buyerOfferAmount: props.buyerOfferAmount!,
            sellerOfferAmount: props.sellerOfferAmount!,
            buyerPersonId: props.buyerPersonId,
            sellerPersonId: props.sellerPersonId,
            submitNegotiationCallback: getSubmitNegotiationCallback(),
            context,
        });

        function getSubmitNegotiationCallback(): (negotiation: SubmitNegotiationDTO) => Promise<void> {
            // submit marketplace negotiation callback 
            if (props.marketplaceListingId && props.marketplaceOfferId && props.storeId) {
                return async (negotiation: SubmitNegotiationDTO) => {
                    await submitMarketplaceNegotiation(negotiation, {
                        marketplaceListingId: props.marketplaceListingId!,
                        marketplaceOfferId: props.marketplaceOfferId!,
                        storeId: props.storeId!,
                        message: negotiationNote.value,
                        offererPersonId,
                    });
                }
            }

            // submit auction negotiation callback
            return async(negotiation: SubmitNegotiationDTO) => {
                await submitNegotiation(props.vehicleListingId!, {
                    ...pick(negotiation, ['highestBuyerOffer', 'lowestSellerOffer', 'disableNotifications']),
                    buyerId: negotiation.buyerPersonId.toString(),
                    sellerId: negotiation.sellerPersonId.toString(),
                    onBuyer: negotiation.negotiatingUser == 'buyer' ? false : true,
                    status: negotiation.negotiationStatus,
                }); 
            }
        }

        async function submitNegotiationAndNote(offerAmount: number) {
            await submitCounterOffer(offerAmount)
                .then(() => {
                    if (!negotiationNote.value || props.marketplaceListingId) { // marketplace listings get their notes submitted when the counteroffer is submitted - see submitMarketplaceCounterOffer in marketplace API
                        return;
                    }
                    createAdminNote(props.vehicleListingId!, negotiationNote.value);
                });
        }
        
        return {
            capitalize,
            toCurrency,

            negotiationNote,
            submitNegotiationAndNote,

            negotiatingUserOfferAmount,
            otherUserOfferAmount,
            otherUser,
            loadingSubmitCounterOffer,
        }
    },
});
