import { AdminVehicleDetailsDTO, DeliveryStatus, FixMe, ImageDTO, InspectionUpdateBookoutOptions, MarketDataDTO, SellerStat, TimeDTO, TitleStatus, VehicleNameDTO, VehiclePhotosDTO, VehicleStatus } from '@/types';
import { BBVehicleFeature } from '@/types/bbVehicleFeature';
import { createCar, GET, getBaseURL, POST, PUT } from '../api';
import { openErrorRefreshAppDialog, openModal, OpenModalConfig, openToast } from './buefyUtils';
import { hubspotUTKFinder } from './otherUtils';
import { capitalizeWord } from './stringUtils';
import { goToDetails } from '@/dashboardUtils';

import TheAssignInspectorModal from '@/components/TheAssignInspectorModal.vue';
import PricingDetailsModal from '@/components/EditVehiclePricingDetailsModal.vue';
import TheEditListingLocationModal from '@/components/TheEditListingLocationModal.vue';
import TheEditVehicleModal from '@/components/TheEditVehicleModal.vue';
import ModalScheduleAuction from '@/components/ModalScheduleAuction.vue';

export function isListingLive(vehicleStatus?: VehicleStatus): boolean {
   return isVehicleInStatus([
    'Auctioning',
    'InMarketplace',
    'SecondChance',
   ], vehicleStatus);
}

export function isMarketplaceStatus(vehicleStatus: VehicleStatus) {
  return isVehicleInStatus([
    'MarketplacePending',
    'InMarketplace',
    'MarketplaceCheckout',
    'MarketplaceSold',
    'MarketplaceCompleted',
    'MarketplaceNotSold'
  ], vehicleStatus);
}

export function isSecondChanceStatus(vehicleStatus: VehicleStatus) {
  return isVehicleInStatus([
    'SecondChance',
    'SecondChanceCheckout',
    'SecondChanceSold',
    'SecondChanceCompleted',
    'SecondChanceNotSold',
  ], vehicleStatus);
}

export function isMarketplaceListingStatusInMarketplaceOrLater(vehicleStatus: VehicleStatus): boolean {
  return isVehicleInStatus(['InMarketplace', 'MarketplaceCheckout', 'MarketplaceSold', 'MarketplaceCompleted', 'MarketplaceNotSold'], vehicleStatus);
}

export function isVehicleStatusCheckoutOrLater(vehicleStatus: VehicleStatus) {
  return [
    'Checkout', 
    'Sold', 
    'Completed', 
    'MarketplaceCheckout', 
    'MarketplaceSold', 
    'MarketplaceCompleted',
    'SecondChanceCheckout',
    'SecondChanceSold',
    'SecondChanceCompleted',
  ].includes(vehicleStatus);
}

export function isVehicleInStatus(checkStatusArr: string[], vehicleStatus?: string) {
  if (!vehicleStatus) {
    return false;
  }
  return checkStatusArr.includes(vehicleStatus);
}

export function isVehicleNotSold(vehicleStatus){
  if (!vehicleStatus) return false;
  return ['NotSold', 'MarketplaceNotSold', 'SecondChanceNotSold'].includes(vehicleStatus);
}

export function isVehicleInCheckoutStatus(vehicleStatus?: VehicleStatus) {
  let checkoutStatuses = [
    'Checkout',
    'SecondChanceCheckout',
    'MarketplaceCheckout',
  ];
  return isVehicleInStatus(checkoutStatuses, vehicleStatus);
}

export function isVehicleSold(vehicleStatus) {
  if (!vehicleStatus) return false;
  const soldStatuses = [
    'Checkout', 
    'Sold', 
    'Completed', 
    'MarketplaceCheckout', 
    'MarketplaceSold', 
    'MarketplaceCompleted',
    'SecondChanceCheckout',
    'SecondChanceSold',
    'SecondChanceCompleted',
  ];
  return soldStatuses.includes(vehicleStatus) || isVehicleNotSold(vehicleStatus);
}

export function isVehicleAuctioned(vehicleStatus) {
  if (!vehicleStatus) return false;
  return vehicleStatus == 'Auctioning' || vehicleStatus == 'Negotiating' || isVehicleSold(vehicleStatus);
}

export function wasVehicleInspected(vehicleStatus) {
  if (vehicleStatus === 'Carmigo Direct') {
    return true;
  }
  return vehicleStatus == 'Inspected' || isVehicleAuctioned(vehicleStatus) || isMarketplaceListingStatusInMarketplaceOrLater(vehicleStatus) || vehicleStatus.toLowerCase().includes('secondchance');
}

export function getVehicleName({ year, make, brand, model, trim }: VehicleNameDTO): string {
  if (!make && !brand) {
    console.log('getVehicleName requires either a Make or a Brand');
    return 'vehicle';
  }
  let arr = [year, make ? make : brand, model, trim];
  return arr.join(' ').trim();
}
  
  export async function getMoreAuctions(
    pagination,
    paginationLimit,
    currentStatus
  ) {
    switch (currentStatus) {
      case "Live":
        this.livePaginationNumber += 36;
        await PUT(`${getBaseURL()}/vehicles/?vehicleStatus=Auctioning`, {
          pagination: pagination,
          paginationLimit: paginationLimit,
        }).then((response) => {
          if (!this.liveAuctions.length) {
            this.liveAuctions = response.data;
            this.loading = false;
            this.auctionsLoaded = true;
          } else {
            response.data.forEach((newVehicle) => {
              this.liveAuctions.push(newVehicle);
            });
            if (!response.data.length) {
              this.moreLiveCars = false;
            }
  
            this.loading = false;
            this.isLoading = false;
            this.auctionsLoaded = true;
          }
        });
        break;
      case "Past":
        this.pastPaginationNumber += 9;
        PUT(`${getBaseURL()}/vehicles/?vehicleStatus=Sold`, {
          pagination: pagination,
          paginationLimit: paginationLimit,
        }).then((response) => {
          if (!this.pastAuctions.length) {
            this.pastAuctions = response.data;
          } else {
            response.data.forEach((newVehicle) => {
              this.pastAuctions.push(newVehicle);
            });
            if (!response.data.length) {
              this.morePastCars = false;
            }
            this.isLoading = false;
          }
        });
        break;
      case "Bidding":
        this.biddingPaginationNumber += 9;
        PUT(`/vehicles/dashboard/Bidding`, { pagination, paginationLimit }).then(res => {
          this.biddingLoaded = false;
          this.loading = false;
          const biddingVehicles = res.data;
          biddingVehicles.forEach(newVehicle => {
            const hasVehicle = this.bidding.some(vehicle => vehicle.id == newVehicle.id);
            if (!hasVehicle) {
              this.bidding.push(newVehicle);
            }
          });
          this.isLoadingBidding = false;
          this.moreBiddingCars = Boolean(biddingVehicles.length);
          this.biddingKey++;
        });
        break;
      case "Purchased":
        this.purchasedPaginationNumber += 9;
        PUT(`/vehicles/dashboard/Purchased`, { pagination, paginationLimit }).then(res => {
          this.isFetching = false;
          this.loading = false;
          const purchasedVehicles = res.data;
          purchasedVehicles.forEach(newVehicle => {
            const hasVehicle = this.purchased.some(vehicle => newVehicle.id == vehicle.id);
            if (!hasVehicle) {
              this.purchased.push(newVehicle);
            }
          });
          this.isLoadingPurchased = false;
          this.morePurchasedCars = Boolean(purchasedVehicles.length);
          this.purchasedKey++;
        });
        break;
      case "Selling":
        if (this.$store.state.isCarmigoDirect) {
          const branding = this.$store.state.branding;
          console.log('pagination limit', paginationLimit);
          PUT(`${getBaseURL()}/carmigoDirect/sellerListings`, {
            storeId: parseInt(branding.storeId),
            paginationOffset: this.selling.length,
            paginationLimit
          }).then(res => {
            this.getAllAuctionsForDashboardSellingArray = res.data;

            if (!res.data.length || res.data.length < paginationLimit) {
              this.moreSellingCars = false;
            }

            res.data.forEach(vehicle => {
              const duplicate = this.selling.some(listing => listing.id == vehicle.id);
              if (!duplicate) this.selling.push(vehicle);
            })

            this.isLoadingSelling = false;
          });
          
        } else {
          this.sellingPaginationNumber += 20;
          const listAllForDashboardSeller = PUT(
            `${getBaseURL()}/vehicles/dashboard/Selling`,
            {
              pagination: pagination,
              paginationLimit: paginationLimit,
            }
          ).then((response) => {
            this.getAllAuctionsForDashboardSellingArray = response.data;
          });
          Promise.all([listAllForDashboardSeller]).then((response) => {
            this.loading = false;
            const allAuctions = this.getAllAuctionsForDashboardSellingArray;
            for (var i = 0; i < allAuctions.length; i++) {
              this.selling.push(allAuctions[i]);
            }
            this.isLoadingSelling = false;
            if (!allAuctions.length) {
              this.moreSellingCars = false;
            }
          });
        }

        break;
      case "Sold":
        this.soldPaginationNumber += 9;
        const listAllForDashboardSold = PUT(
          `${getBaseURL()}/vehicles/dashboard/Sold`,
          {
            pagination: pagination,
            paginationLimit: paginationLimit,
          }
        ).then((response) => {
          this.getAllAuctionsForDashboardSoldArray = response.data;
        });
        Promise.all([listAllForDashboardSold]).then((response) => {
          this.loading = false;
          const allAuctions = this.getAllAuctionsForDashboardSoldArray;
          for (var i = 0; i < allAuctions.length; i++) {
            this.sold.push(allAuctions[i]);
          }
          this.isLoadingSold = false;
          if (!allAuctions.length) {
            this.moreSoldCars = false;
          }
          if (!allAuctions.length) {
            this.moreSoldCars = false;
          }
        });
        break;
      case "Negotiating":
        this.negotiatingPaginationNumber += 9;
        this.negotiatingPaginationAmount += 9;
        PUT(`/vehicles/dashboard/Negotiating`, { pagination, paginationLimit }).then(res => {
          this.loading = false;
          const negotiatingVehicles = res.data;
          negotiatingVehicles.forEach(newVehicle => {
            const hasVehicle = this.negotiating.some(vehicle => vehicle.id == newVehicle.id);
            if (!hasVehicle) {
              this.negotiating.push(newVehicle);
            }
          });
          this.isLoadingNegotiating = false;
          this.moreNegotiatingCars = Boolean(negotiatingVehicles.length);
          this.negotiatingKey++;
        });
        break;
      case "Inactive":
        this.inactivePaginationNumber += 20;
        const listAllForDashboardInactive = PUT(
          `${getBaseURL()}/vehicles/dashboard/Inactive`,
          {
            pagination: pagination,
            paginationLimit: paginationLimit,
          }
        ).then((response) => {
          this.getAllAuctionsForDashboardInactiveArray = response.data;
        });
        Promise.all([listAllForDashboardInactive]).then((response) => {
          this.loading = false;
          const allAuctions = this.getAllAuctionsForDashboardInactiveArray;
          for (var i = 0; i < allAuctions.length; i++) {
            this.selling.push(allAuctions[i]);
          }
          this.isLoadingInactive = false;
          if (!allAuctions.length) {
            this.moreInactiveCars = false;
          }
        });
        break;
    }
  }

  export async function getVehiclePurchasePrice(vehicleListingId: number) {
    return await GET(`/vehicles/purchasePrice/${vehicleListingId}`);
  }
  

export async function getMissingOrderSummaryOrVehiclePrice(vehicle: any) {
  if (vehicle.orderSummary && vehicle.orderSummary.vehiclePrice) {
    return vehicle;
  }

  await Promise.all([
    GET(`/vehicles/order/${vehicle.id}`).then(res => {
      vehicle.orderSummary = {
        ...vehicle.orderSummary,
        ...res.data
      };
    }),
    GET(`/vehicles/${vehicle.id}/getBuyerDetails`).then(res => {
      vehicle.buyer = {
        ...vehicle.buyer,
        ...res.data
      }
    }),
    getVehiclePurchasePrice(vehicle.id).then(res => {
      vehicle.orderSummary = {
        ...vehicle.orderSummary,
        ...res.data
      }
    })
  ]);

  return vehicle;  
}

export function getVehicleGraphicUrl(vehicleListingId: number, vehicleDetails: { bodyType?: string, color?: string } | undefined = undefined) {
  if (!vehicleDetails) {
    vehicleDetails = {};
  }
  if (!vehicleDetails.bodyType) {
    vehicleDetails.bodyType = 'sedan';
  }
  if (!vehicleDetails.color) {
    vehicleDetails.color = 'gray';
  }
  var bodyType = vehicleDetails.bodyType.toLowerCase();
  var color = vehicleDetails.color;
  
  if (bodyType.includes('suv')) {
    bodyType = 'SUV';
  } else if (bodyType.includes('van')) {
    bodyType = 'van';
  } else if (bodyType.includes('truck') || bodyType.includes('pickup')) {
    bodyType = 'truck';
  } else {
    bodyType = 'sedan';
  }

  return `${capitalizeWord(color)}${capitalizeWord(bodyType)}.png`;
}


export async function createVehicle(myNewCar: any, user: any, originPublicId: string, isCarmigoDirect: boolean, referralCode: string, promotionCode='') {
  const vehicle = {
    ...myNewCar,
    user: user.email,
    blackbook: myNewCar?.bbInfo,
    hubSpotUTK: hubspotUTKFinder(),
    hasAcceptedPromotionalEmails: true,
    referralCode,
    originPublicId,
    promotionCode,
    isCarmigoDirect,
  };

  const vehicleListing = await createCar(vehicle).then(res => res.data);

  if (isCarmigoDirect) {
    await POST('/carmigoDirect/createListing', {
      vehicleListingId: vehicleListing.id,
      originPublicId,
    }).catch((err) => {
      console.log('Error creating Carmigo Direct listing', err);
      openErrorRefreshAppDialog(
        undefined,
        `<strong>There was an error saving your vehicle.</strong>
          <br><br>
          Please refresh the page. If the problem persists, contact support.`
      );
    });
  }
  // DO THESE from file that calls this
  // this.$store.commit('resetMyNewCarTemp');
  // this.$store.commit('resetReferralCode');
  // this.$store.state.myNewCar = vehicleListing.data;
  return vehicleListing;
}

export function createVehiclePricingDetailsModal(vehicleListingId: string | number, options?: OpenModalConfig): Promise<Record<string, number | null> | null> {
    return new Promise((resolve) => {
        openModal({
            ...options,
            component: PricingDetailsModal,
            hasModalCard: true,
            events: {
                update: async (payload: Record<string, string>) => {
                    const updatedDetails = {
                        blackbook_trade: Number(payload.blackbook_trade) || null,
                        retail: Number(payload.retail) || null,
                        jd_power: Number(payload.jd_power) || null,
                        mmr: Number(payload.mmr) || null,
                        kbb_trade: Number(payload.kbb_trade) || null,
                        kbb_private: Number(payload.kbb_private) || null,
                    };
                    try {
                        await PUT(`/vehicles/${vehicleListingId}/updatePricingDetails`, updatedDetails);
                        openToast('is-success', 'Pricing details successfully updated.');
                        resolve(updatedDetails);
                    } catch (error) {
                        openToast('is-danger', `Unable to save pricing details for vehicle ${vehicleListingId}`);
                        resolve(null);
                    }
                },
            },
        });
    });
}


export function goToAuctionOrMarketplaceDetails({ vehicleListingId, marketplaceListingId, vehicleStatus, activeTab, openInNewTab }: {
  vehicleListingId: number,
  marketplaceListingId?: number,
  vehicleStatus: VehicleStatus,
  activeTab: number,
  openInNewTab?: boolean
}) {
    goToDetails({
        vehicleListingId: vehicleListingId.toString(),
        marketplaceListingId: marketplaceListingId && isMarketplaceStatus(vehicleStatus) ? marketplaceListingId.toString() : undefined,
        activeTab: activeTab.toString(),
        openInNewTab,
    });
}

export function formatSellerArbitrationRate(arbitrationRate?: number): string | undefined {
  return typeof arbitrationRate == 'number' ? `${(arbitrationRate * 100).toFixed()}%` : undefined;
}

export function formatSellerSellRate(sellRate?: number): string | undefined{
  return sellRate ? `${(sellRate * 100).toFixed()}%` : undefined;
}

export function formatSellerTimeToTitle(timeToTitle?: TimeDTO): string | undefined {
  if (!timeToTitle) {
    return undefined;
  }

  if (!timeToTitle.days || timeToTitle.days == 0) {
    return '< 1 day';
  }

  if (timeToTitle.days == 1) {
    return '1 day';
  }

  return `${timeToTitle.days} days`;
}

export function formatSellerStat(statName: SellerStat, stat?: number | TimeDTO) {
  switch(statName) {
    case 'Arbitration Rate':
      return formatSellerArbitrationRate(stat as number | undefined);
    case 'Sell Rate':
      return formatSellerSellRate(stat as number | undefined);
    case 'Time to Title':
      return formatSellerTimeToTitle(stat as TimeDTO | undefined);
  }
}

export function convertVehiclePhotosDtoToImageDtoArray(vehiclePhotos: VehiclePhotosDTO): ImageDTO[] {
  let images: ImageDTO[] = [];
  return images.concat(vehiclePhotos.exterior.images, vehiclePhotos.interior.images, vehiclePhotos.damage.images)
}

export function formatTitleStatusForDisplay(titleStatus?: TitleStatus, facilitatingAuctionStoreName?: string): { status?: TitleStatus, label: FixMe, type?: string, class?: string } | undefined {
  let beforeTransitColor = 'has-text-grey is-grey-light';
  let beforeTransitType = 'is-grey';
  let inTransitColor = 'has-text-orange is-orange';
  let inTransitType = 'is-orange';
  let deliveredColor = 'has-text-primary is-primary';
  let deliveredType = 'is-primary';

  let defaultConfig = {
    status: titleStatus,
  }

  if (facilitatingAuctionStoreName) {
    return {
      ...defaultConfig,
      label: `Check with ${facilitatingAuctionStoreName}`,
      class: `${beforeTransitColor}`,
      type: beforeTransitType,
    }
  }
  if (!titleStatus) {
    return undefined;
  }

  switch(titleStatus.toLowerCase()) {
    // present/absent
    case 'title present':
      return {
        ...defaultConfig,
        label: titleStatus,
        type: beforeTransitType,
        class: `${beforeTransitColor}`,
      }
    case 'title absent':
      return {
        ...defaultConfig,
        label: titleStatus,
        type: beforeTransitType,
        class: `${beforeTransitColor}`,
      }
    // with seller
    case 'with seller':
      return {
        ...defaultConfig,
        label: 'Title with seller',
        type: beforeTransitType,
        class: `${beforeTransitColor}`,
      }
    // with bank
    case 'with bank':
      return {
        ...defaultConfig,
        label: 'Title with bank',
        type: beforeTransitType,
        class: `${beforeTransitColor}`,
      }
    // lost
    case 'lost':
    case 'title problem':
      return {
        ...defaultConfig,
        label: 'Title lost',
        type: beforeTransitType,
        class: `${beforeTransitColor}`,
      }
    // In transit
    case 'in transit to carmigo':
    case 'in transit to buyer':
    case 'paid off':
    case 'with carmigo':
      return {
        ...defaultConfig,
        label: 'Title is in transit',
        type: inTransitType,
        class: `${inTransitColor}`,
      }
    // delivered
    case 'delivered':
      return {
        ...defaultConfig,
        label: 'Title delivered',
        type: deliveredType,
        class: `${deliveredColor}`,
      }
    default: 
      return undefined;
  }
}

export function formatDeliveryStatusForDisplay(deliveryStatus?: DeliveryStatus, facilitatingAuctionStoreName?: string): { status?: DeliveryStatus, label: string, type?: string, class?: string } | undefined{
  let beforeTransitColor = 'has-text-grey is-grey-light';
  let beforeTransitType = 'is-grey';
  let inTransitColor = 'has-text-orange is-orange';
  let inTransitType = 'is-orange';
  let deliveredColor = 'has-text-primary is-primary';
  let deliveredType = 'is-primary';
  
  let defaultConfig = {
    status: deliveryStatus,
  }
  if (facilitatingAuctionStoreName) {
    return {
      ...defaultConfig,
      label: `Check with ${facilitatingAuctionStoreName}`,
      type: beforeTransitType,
      class: `${beforeTransitColor}`,
    }
  }

  switch(deliveryStatus?.toLowerCase()) {
    // with seller
    case 'not scheduled':
      return {
        ...defaultConfig,
        label: 'Scheduling vehicle pickup',
        type: beforeTransitType,
        class: `${beforeTransitColor}`,
      }
    // scheduled
    case 'scheduled':
      return {
        ...defaultConfig,
        label: 'Vehicle pickup scheduled',
        type: inTransitType,
        class: `${inTransitColor}`,
      }
    // in transit
    case 'in transit':
      return {
        ...defaultConfig,
        label: 'Vehicle is in transit',
        type: inTransitType,
        class: `${inTransitColor}`,
      }
    // delivered
    case 'delivered to holding lot':
    case 'delivered to buyer':
      return {
        ...defaultConfig,
        label: 'Vehicle delivered',
        type: deliveredType,
        class: `${deliveredColor}`,
      }
    default: 
      return undefined;
  }
}

export function openAssignInspectorModal(vehicleListingId: number, inspectorPersonId?: number | null, options?: { emit: (event: 'assignInspector', ...args: any[]) => void }) {
  openModal({
    component: TheAssignInspectorModal,
    props: {
      vehicleListingId,
      inspectorPersonId,
    },
    events: {
      assignInspector: (value: any) => {
        options?.emit ? options.emit('assignInspector', value?.personId) : ''
      },
    }
  });
}

export function openEditListingLocationModal(vehicleListingId: number, location: {
  zipCode?: string,
  city?: string,
  state?: string,
}) {
  openModal({
    component: TheEditListingLocationModal,
    props: {
      vehicleListingId,
      ...location,
    }
  });
}

export function openEditVehicleModal(vehicleListingId: number, details: AdminVehicleDetailsDTO) {
  openModal({
    component: TheEditVehicleModal,
    props: {
      vehicleListingId,
      vehicleDetails: details,
    }
  });
}

export function openScheduleAuctionModal(vehicleListingId: number, config: { onSuccess?: () => void }={}) {
    openModal({
        component: ModalScheduleAuction,
        props: {
            vehicleListingId,
            autofillEndTime: true,
        },
        events: {
            success: () => config.onSuccess ? config.onSuccess() : '',
        }
    });
}

export function convertBBFeaturesToBookoutOptions(bbFeatures?: Array<BBVehicleFeature & { price: number }>, showSelectedOnly: boolean=true): InspectionUpdateBookoutOptions | undefined {
  if (!bbFeatures) {
    return undefined;
  }
  
  let bookoutOptions =  bbFeatures.map((bb) => {
    return {
      name: bb.name,
      price: bb.price ?? null,
      selected: bb.selected ?? false,
    }
  });

  return showSelectedOnly
    ? bookoutOptions.filter(option => option.selected)
    : bookoutOptions;
}
