var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheListingDetailsSellerDetails',_vm._b({scopedSlots:_vm._u([{key:"titleStatus",fn:function(){return [(_vm.isEditingMode)?_c('BuefyDropdown',_vm._b({staticClass:"width-100",attrs:{"initialValue":_vm.updatedFields.titleStatus},on:{"update:initialValue":function($event){return _vm.$set(_vm.updatedFields, "titleStatus", $event)},"update:initial-value":function($event){return _vm.$set(_vm.updatedFields, "titleStatus", $event)},"select":_vm.emitUpdatedValues}},'BuefyDropdown',{
                options: [
                    'With Seller',
                    'With Bank',
                    'Unknown',
                ],
                position: 'is-top-right',
                appendToBody: true,
                buttonAttrs: { expanded: true },
                expanded: true,
            },false)):_vm._e()]},proxy:true},{key:"sellerType",fn:function(){return [(_vm.isEditingMode)?_c('BuefyDropdown',_vm._b({staticClass:"width-100",attrs:{"initialValue":_vm.updatedFields.sellerType},on:{"update:initialValue":function($event){return _vm.$set(_vm.updatedFields, "sellerType", $event)},"update:initial-value":function($event){return _vm.$set(_vm.updatedFields, "sellerType", $event)},"select":_vm.emitUpdatedValues}},'BuefyDropdown',{
                options: [
                    'Individual',
                    'Dealer',
                    'Fleet',
                    'Bank',
                ],
                position: 'is-top-right',
                appendToBody: true,
                buttonAttrs: { expanded: true },
                expanded: true,
            },false)):_vm._e()]},proxy:true},{key:"sellerReason",fn:function(){return [(_vm.isEditingMode)?_c('EditableField',_vm._b({on:{"input":_vm.emitUpdatedValues},model:{value:(_vm.updatedFields.sellerReason),callback:function ($$v) {_vm.$set(_vm.updatedFields, "sellerReason", $$v)},expression:"updatedFields.sellerReason"}},'EditableField',{ customInputHandler: true },false)):_vm._e()]},proxy:true},{key:"storeName",fn:function(){return [(_vm.isEditingMode && _vm.updatedFields.sellerType == 'Dealer')?_c('div',{staticClass:"flex-column width-100"},[(_vm.isEditingSeller || !_vm.selectedStoreEmployee)?_c('AutocompleteInput',_vm._b({class:{ 'selected-seller': Boolean(_vm.selectedStoreEmployee) },attrs:{"value":_vm.selectedStoreEmployee},on:{"update:value":function($event){_vm.selectedStoreEmployee=$event},"select":function($event){_vm.emitUpdatedValues($event); _vm.isEditingSeller = false}},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('span',[_vm._v(_vm._s(option.firstName)+" "+_vm._s(option.lastName)+" - "+_vm._s(option.storeName))])]}}],null,false,1889689545)},'AutocompleteInput',{
                    options: _vm.allStoreEmployees,
                    dropdownPosition: 'top',
                    expanded: true,
                    loading: _vm.loadingStoreEmployees,
                    autofocus: false,
                    field: 'displayValue',
                },false)):_vm._e(),(_vm.selectedStoreEmployee && !_vm.isEditingSeller)?_c('div',{staticClass:"change-seller-container flex-row justify-space-between"},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.selectedStoreEmployee.firstName)+" "+_vm._s(_vm.selectedStoreEmployee.lastName))]),_c('p',{staticClass:"bold"},[_vm._v(_vm._s(_vm.selectedStoreEmployee.storeName))])]),_c('a',{staticClass:"has-text-primary underline min-width-fit-content",on:{"click":function($event){_vm.isEditingSeller = true}}},[_vm._v("Change Seller")])]):_vm._e()],1):(_vm.updatedFields.sellerType !== 'Dealer')?_c('div'):_vm._e()]},proxy:true}])},'TheListingDetailsSellerDetails',{ 
    storeLabel: 'Seller',
    ..._vm.$props, 
    ..._vm.updatedFields, 
    showAllDetails: true, 
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }