import { APIConfig, Roles, StoreEmployee, StoresDTO } from '@/types';
import { ModifierOptions } from '@/types/ModifierOptions';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, openErrorDialog } from '@/utils';
import { GET, POST, PUT } from '.';

export async function createCompany(company: { company: string }, config: APIConfig={}) {
    return await POST(`/company`, company)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to create company',
                message: celebrateValidationMessage ?? `We encountered an error while creating a new company. Try again or contact support.`,
                error,
            });
        });
}

export async function getCompanies(modifiers: ModifierOptions, config: APIConfig={}) {
    return await PUT(`/company/getCompanies`, { modifiers })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to fetch companies',
                message: celebrateValidationMessage ?? `We encountered an error while fetching companies`,
                error,
            });
        });
}

export async function getStoreById(storeId: number, config: APIConfig={}): Promise<StoresDTO> {
    return await GET(`/company/store/${storeId}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function addStore(store: StoresDTO, config: APIConfig={}) {
    return await POST(`/company/store`, store)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to save store',
                message: `We encountered an error while saving this store. Please try again or contact support.`, 
                displayErrorInDialog: true,
                error,
            });
        });
}

export async function updateStore(storeId: number, store: Partial<StoresDTO>, config: APIConfig={}) {
    return await PUT(`/company/store/${storeId}?id=${store.companyId}`, store)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to save store',
                message: `We encountered an error while updating store ${store.id}. Please try again or contact support.`, 
                displayErrorInDialog: true,
                error,
            });
        });
}

export async function getAllMarkets(config: APIConfig={}) {
    return await GET(`/company/getAllMarkets`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch markets',
                message: `We encountered an error while fetching markets.`,
                error,
            });
        });
}

export async function updateCompanyNameByCompanyId(companyId: number, companyName: string, config: APIConfig={}) {
    return await PUT(`/company/updateCompanyName`, { companyId, companyName: companyName })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to update company details',
                message: celebrateValidationMessage ?? `We encountered an error while updating company details for company ${companyId}`,
                error,
            });
        });
}

export async function assignStorePointOfContact(payload: { storeId: number, userId: number }, config: APIConfig={}) {
    return await PUT(`/company/assignPointOfContact`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to assign point of contact',
                message: celebrateValidationMessage ?? `We encountered an error while assigning user ${payload.userId} as the point of contact for store ${payload.storeId}`,
                error,
            });
        });
}

export async function getAllStoreEmployees(payload: { role: Roles }, config: APIConfig={}): Promise<StoreEmployee[]> {
    return await POST(`/company/getAllStoreEmployees`, payload, { cancelToken: config.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}