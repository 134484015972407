import { getTransportationCostUsingZipCodes } from '@/api';
import store from '@/vuex';
import { openModal } from './buefyUtils';

import TheAddTrackingLinkModal from '@/components/TheAddTrackingLinkModal.vue';
import TheTrackingLinkUnavailableModal from '@/components/TheTrackingLinkUnavailableModal.vue';

export function setDefaultTransportationZip() {
  if (store.state.transportation.zip) {
    return;
  }
  const stores = store.getters.getStores;
  if (stores.length) {
    store.commit('updateTransportation', { zip: stores[0].transportZip });
  }
}

export async function getTransportationCostFromSavedUserZip({ listingZip, vehicleListingId }: { listingZip: string, vehicleListingId: number }): Promise<number | undefined> {
  const zip1 = store?.getters.getTransportationZip; // Buyers zip
  if (!zip1 || !listingZip) {
    return undefined;
  }
  
  return await getTransportationCostUsingZipCodes({
    zip1,
    zip2: listingZip,
    vehicleListingId,
  });
}

export function openTrackingLinkUnavailableModal(trackingType: 'vehicle' | 'title') {
  openModal({
    component: TheTrackingLinkUnavailableModal,
    props: {
      trackingType,
    },
  });
}

export function openAddTrackingLinkModal(trackingType: 'vehicle' | 'title', vehicleListingId: number) {
  openModal({
    component: TheAddTrackingLinkModal,
    props: {
      trackingType,
      vehicleListingId,
    },
  });
}