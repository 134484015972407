
import { defineComponent, PropType, Ref, ref } from 'vue';
import { formatDeliveryStatusForDisplay, formatTitleStatusForDisplay, openLink, openTrackingLinkUnavailableModal } from '../utils';
import { FixMe, TitleDelivery, VehicleDelivery } from '../types';
import { useCancelToken, useFetchDeliveryStatus, useFetchTitleStatus } from '../composables';
import { fireEvent } from '@/segment';

import AppStatusIcon from './AppStatusIcon.vue';

export default defineComponent({
    name: 'TheTitleAndDeliveryStatusIcons',
    props: {
        vehicleListingId: {
            type: Number,
            required: false
        }, 
        titleStatus: {
            type: Object as PropType<TitleDelivery>,
            required: false,
        },
        deliveryStatus: {
            type: Object as PropType<VehicleDelivery>,
            required: false
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppStatusIcon,
    },
    setup(props) {
        const titleStatusFormatted: Ref<FixMe> = ref(formatTitleStatusForDisplay(props.titleStatus?.titleStatus));
        const deliveryStatusFormatted: Ref<FixMe>= ref(formatDeliveryStatusForDisplay(props.deliveryStatus?.deliveryStatus));
        const titleTrackingLink = ref<string | undefined>(props.titleStatus?.trackingLink);
        const deliveryTrackingLink = ref<string | undefined>(props.deliveryStatus?.trackingLink);

        const { cancelToken: titleStatusCancelToken, createNewCancelToken: createNewTitleStatusCancelToken } = useCancelToken();
        if (!props.titleStatus && props.vehicleListingId) {
            createNewTitleStatusCancelToken();
            useFetchTitleStatus(props.vehicleListingId, {
                onSuccess: (response, data) => {
                    titleStatusFormatted.value = formatTitleStatusForDisplay(data.value?.titleStatus);
                    titleTrackingLink.value = data.value?.trackingLink;
                },
                cancelToken: titleStatusCancelToken.value?.token,
            });
        }

        const { cancelToken: deliveryStatusCancelToken, createNewCancelToken: createNewDeliveryStatusCancelToken } = useCancelToken();
        if (!props.deliveryStatus && props.vehicleListingId) {
            createNewDeliveryStatusCancelToken();
            useFetchDeliveryStatus(props.vehicleListingId, {
                onSuccess: (response, data) => {
                    deliveryStatusFormatted.value = formatDeliveryStatusForDisplay(data.value?.deliveryStatus);
                    deliveryTrackingLink.value = data.value?.trackingLink;
                },
                cancelToken: deliveryStatusCancelToken.value?.token,
            });
        }

        function openTrackingLink(trackingType: 'vehicle' | 'title', trackingLink?: string) {
            fireEvent(`${trackingType} tracking link clicked`);
            if (trackingLink) {
                // Check if trackingLink starts with 'http'
                if (!trackingLink.startsWith('http')) {
                    trackingLink = 'https://' + trackingLink; // Prepend 'https://'
                }
                openLink(trackingLink);
                return;
            }
            openTrackingLinkUnavailableModal(trackingType);
            fireEvent(`${trackingType} tracking unavailable`);
        }

        return {
            titleStatusFormatted,
            deliveryStatusFormatted,
            titleTrackingLink,
            deliveryTrackingLink,
            openTrackingLink,
        }
    },
});
