
import { computed, defineComponent, PropType } from 'vue';
import { formatPhoneNumber } from '@/utils';

import Modal from '@/components/Modal.vue';

export default defineComponent({
    name: 'TheTrackingLinkUnavailableModal',
    props: {
        trackingType: {
            type: String as PropType<'vehicle' | 'title'>,
            default: 'vehicle',
        },
    },
    components: {
        Modal,
    },
    setup(props, context) {
        const contactPhoneNumber = computed(() => {
            switch (props.trackingType) {
                case 'title':
                    return '662-321-2226';
                case 'vehicle':
                    return '662-321-2624';
            }
        });
        return {
            formatPhoneNumber,
            contactPhoneNumber,
        }
    }
});
