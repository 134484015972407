import { getActivityFeedByVehicleListingId, getAdditionalPricingDetailsByVehicleListingId } from '@/api';
import { 
    AdditionalPricingDTO,
    APIConfig,
    NoteDTO, 
} from '@/types';
import { applyAPIConfigOnSuccess, createVehiclePricingDetailsModal } from '@/utils';
import { Ref, ref } from 'vue';

export function useActivityFeed({ vehicleListingId }: {
    vehicleListingId: number,
}) {
    const loadingActivityFeed: Ref<boolean> = ref(false);
    const activityFeed: Ref<NoteDTO[]> = ref([]);
    async function getActivityFeed() {
        loadingActivityFeed.value = true;
        activityFeed.value = await getActivityFeedByVehicleListingId(vehicleListingId, {
            onSuccess: () => loadingActivityFeed.value = false,
            onError: () => loadingActivityFeed.value = false,
        });
    }

    return {
        getActivityFeed,
        activityFeed,
        loadingActivityFeed,
    }
}


export function useAdditionalPricingModal({ config }: { config: APIConfig }) {
    const loadingAdditionalPricingVehicleListingId: Ref<number | undefined> = ref(undefined);
    async function openAdditionalPricingModal(vehicleListingId: number) {
        loadingAdditionalPricingVehicleListingId.value = vehicleListingId;
        await getAdditionalPricingDetailsByVehicleListingId(vehicleListingId, {
            onSuccess: async(pricingDetails: AdditionalPricingDTO) => {
                loadingAdditionalPricingVehicleListingId.value = undefined;
                let updatedPricing = await createVehiclePricingDetailsModal(vehicleListingId, { props: { pricingDetails }});
                applyAPIConfigOnSuccess({ updatedPricing, vehicleListingId }, config);
            },
            onError: () => loadingAdditionalPricingVehicleListingId.value = undefined,
        });
    }

    return {
        openAdditionalPricingModal,
        loadingAdditionalPricingVehicleListingId,
    }
}