var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-field',{staticClass:"autocomplete-input",attrs:{"label":_vm.$attrs.label}},[_c('b-autocomplete',_vm._b({attrs:{"id":"autocomplete-input"},on:{"input":_vm.emitInput,"select":function($event){_vm.$emit('select', $event); _vm.$emit('update:value', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("option",function(){return [_vm._v(" "+_vm._s(props.option[_vm.field])+" ")]},null,{ option: props.option })]}},{key:"empty",fn:function(){return [_vm._t("empty")]},proxy:true}],null,true),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},'b-autocomplete',{
            data: _vm.filteredData,
            openOnFocus: true,
            clearable: true,
            field: _vm.field,
            placeholder: 'Select',
            ..._vm.$attrs,
        },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }