
import { defineComponent, onMounted, PropType, ref  } from 'vue';
import { SRPListing } from '../types';
import { useEditableFields, useEditableVDP, useInspectorVDP, usePreAuctionFunctions, useUserRole } from '../composables';
import { checkForRecentVehicleRelistingByVin, submitInspection } from '../api';
import { useRoute } from 'vue-router/composables';
import { isVehicleAuctioned, isVehicleSold, openModal, openToast } from '../utils';
import router from '@/router';

import AppListingDetailsEditable from './AppListingDetailsEditable.vue';
import AppButton from '../components/AppButton.vue';
import TheAssignInspectorEditableField from '../components/TheAssignInspectorEditableField.vue';
import VehicleRecentlyRelistedModal from '../components/VehicleRecentlyRelistedModal.vue';

export default defineComponent({
    name: 'TheListingDetailsInspector',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
    },
    components: {
        AppButton,
        AppListingDetailsEditable,
        TheAssignInspectorEditableField,
    },
    emits: ['input'],
    setup(props, context) {
        const route = useRoute();
        const vehicleListingId = Number(route.params.vehicleListingId);

        // don't allow DSRs to edit listings after it has been auctioned
        const { isUserDsr } = useUserRole();
        let isDsrAndListingIsAuctioned = isUserDsr.value && isVehicleAuctioned(props.listing.status);
        const isEditingMode = ref(isDsrAndListingIsAuctioned ? false : true);
        function toggleEditingMode(isEditing: boolean) {
            if (isDsrAndListingIsAuctioned) {
                return;
            }
            isEditingMode.value = isEditing;
        }

        // get listing additional details
        const {
            vehiclePhotos,
            loadingVehiclePhotos,
            vehiclePhotosDTO,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            titleDelivery: titleStatus,
            loadingTitleStatus,
            sellerReason,
            loadingSellerReason,
            additionalPricingDetails,
            loadingAdditionalPricingDetails,
        } = useInspectorVDP({ vehicleListingId });

        // open model to Mark Inactive if vehicle has recently been listed
        onMounted(() => {
            checkForRecentVehicleRelistingByVin(props.listing.vin)
                .then(recentlyListedVehicle => {
                    if (recentlyListedVehicle?.startDate) {
                        openModal({
                            component: VehicleRecentlyRelistedModal,
                            props: {
                                make: props.listing.brand,
                                model: props.listing.model,
                                year: props.listing.year.toString(),
                                vin: props.listing.vin,
                                sellerFirstName: recentlyListedVehicle.firstName,
                                sellerLastName: recentlyListedVehicle.lastName,
                                sellerEmail: recentlyListedVehicle.email,
                                sellerPhoneNumber: recentlyListedVehicle.phoneNumber,
                                auctionStartDate: new Date(recentlyListedVehicle.startDate),
                                vehicleListingId,
                                vehicleHeroImageUrl: recentlyListedVehicle.vehicleHeroImageUrl,
                            },
                        });
                    }
                });
        })

        // get refs for editable fields
        // syncs editable values with AppListingDetailsEditable
        const { unsavedUpdates, addToUnsavedUpdates, hasUnsavedChanges } = useEditableFields({ context });

        // updates VDP DTOs with edited values
        // saves listing edits to db
        const { 
            saveUpdates, 
            loadingSaveUpdates, 
            updatedListing, 
            listingKey, 
            updateListingFromSaveUpdatesResponse, 
            updateListingFromUnsavedUpdates,
            updateListingVehiclePhotos, 
            movePricingToBasicInfoForInspectionPayload, 
            filterOutSellerReasonIfSellerTypeIsNotIndividual,
            filterOutSellerStoreEmployeeIdIfSellerTypeIsNotDealer,
            filterOutBookoutOptionsIfNoName,
        } = useEditableVDP({
            vehicleListingId,
            originalListing: props.listing,
            unsavedUpdates,
            announcements, 
            bookoutOptions, 
            vehiclePhotos,
            vehiclePhotosDTO,
            sellerReason,
        });

        async function saveInspection() {
            const pricing = movePricingToBasicInfoForInspectionPayload();
            filterOutSellerReasonIfSellerTypeIsNotIndividual();
            filterOutSellerStoreEmployeeIdIfSellerTypeIsNotDealer();
            filterOutBookoutOptionsIfNoName();
            await saveUpdates({
                pricing,
                onSuccess: ({ response, sectionName, pricing }) => { 
                    updateListingFromSaveUpdatesResponse({ response, sectionName, pricing });
                },
                onPromiseSuccess: () => {
                    openToast('is-success', 'Changes saved!');
                    unsavedUpdates.value = {};
                },
            });
            // orderAnnouncementsByType();
        }

        const { selectSellFeeAndScheduleAuction } = usePreAuctionFunctions({ vehicleListingId });

        const loadingSubmitInspection = ref(false);
        function submit() {
            loadingSubmitInspection.value = true;
            submitInspection(vehicleListingId, {
                onSuccess: () => {
                    selectSellFeeAndScheduleAuction({
                        sellerStoreId: props.listing.sellerStore?.id,
                        vehicleStatus: props.listing.status,
                        onError: (error: string) => {
                            loadingSubmitInspection.value = false;
                            goToInspectorDashboard();
                        },
                        onSellFeeClose: (event?) => {
                            if (event?.isCancelled) {
                                loadingSubmitInspection.value = false;
                            }
                        },
                        onSellFeeSelected: () => {
                            loadingSubmitInspection.value = false;
                            openToast('is-success', 'Inspection submitted!');
                            goToInspectorDashboard();
                        },
                        onAuctionScheduled: () => {
                            loadingSubmitInspection.value = false;
                            openToast('is-success', 'Auction scheduled!');
                            goToInspectorDashboard();
                        }
                    });
                },
                onError: () => {
                    loadingSubmitInspection.value = false;
                    isEditingMode.value = true;
                },
            });
        }

        function goToInspectorDashboard() {
            router.push('/inspector');
        };

        return {
            toggleEditingMode,
            isDsrAndListingIsAuctioned,
            isVehicleSold,
            updatedListing,
            listingKey,
            addToUnsavedUpdates,
            updateListingFromUnsavedUpdates,
            hasUnsavedChanges,
            isEditingMode,
            vehicleListingId,
            vehiclePhotos,
            loadingVehiclePhotos,
            vehiclePhotosDTO,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            titleStatus,
            loadingTitleStatus,
            sellerReason,
            loadingSellerReason,
            additionalPricingDetails,
            loadingAdditionalPricingDetails,
            submit,
            loadingSubmitInspection,
            saveInspection,
            loadingSaveUpdates,
            updateListingVehiclePhotos,
        }
    }
});
