import { getCurrentNegotiationOffer, getNegotiatingEmployees, getNegotiations } from '@/api';
import { AdminNegotiationTable, APIConfig, CarmigoEmployeeDTO, CurrentNegotiationDTO, FilterFieldOptionsSchema, HighestBidRefSnapshot, Negotiation, SubmitNegotiationDTO } from '@/types';
import { negotiationgStatus } from '@/types/NegotiationStatus';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, calculateBuyerOfferPercentOfMmr, dollarAmountToInt, getNegotiationSearchModifiers, isMultipleOfHundred, openAdminNegotiationModal, openErrorDialog, openModal, OpenModalConfig, openToast, toCurrency } from '@/utils';
import { capitalize, uniqBy } from 'lodash';
import { computed, ComputedRef, Ref, ref, SetupContext } from 'vue';
import { useCancelToken, useFetch } from './fetch';
import { useUserRole } from './user';
import axios, { CancelTokenSource } from 'axios';
import Vue from 'vue';
import store from '@/vuex';
import { NegotiationTypeEnum } from '@/enums';

import TheNegotiationModalAuction from '@/components/TheNegotiationModalAuction.vue';

export function useGetOfferDisplayValues(negotiatingUser: 'buyer' | 'seller', buyerOfferAmount: number, sellerOfferAmount: number) {
    const yourOffer = computed(() => {
        return negotiatingUser == 'buyer' ? buyerOfferAmount : sellerOfferAmount;
    });
    const theirOffer = computed(() => {
        return negotiatingUser == 'buyer' ? sellerOfferAmount : buyerOfferAmount;
    });
    const otherUser = computed(() => {
        return negotiatingUser == 'seller' ? 'buyer' : 'seller';
    });

    return {
        yourOffer,
        theirOffer,
        otherUser,
    }
}

export function useValidateCounterOffer(buyerOfferAmount: number, sellerOfferAmount: number, isMarketplace: boolean=false) {
    function offerTooLow(offer: number) {
        return offer <= buyerOfferAmount;
    }

    function offerTooHigh(offer: number) {
        if (!sellerOfferAmount) {
            return false;
        }
        return isMarketplace 
            ? offer > sellerOfferAmount 
            : offer >= sellerOfferAmount;
    }

    const invalidCounterOfferMessage = ref('');

    function validateCounterOffer(inputValueStr: string) {
        const inputValueInt = dollarAmountToInt(inputValueStr);
        if (!inputValueInt) {
            return true;
        }
        if (!isMultipleOfHundred(inputValueInt)) {
            invalidCounterOfferMessage.value = 'Please use multiples of $100';
            return false;
        }
        if (offerTooLow(inputValueInt)) {
            invalidCounterOfferMessage.value = `Offer should be greater than ${toCurrency(buyerOfferAmount)}`;
            return false;
        }
        if (offerTooHigh(inputValueInt)) {
            invalidCounterOfferMessage.value = `Offer should be less than ${toCurrency(sellerOfferAmount)}`;
            return false;
        }
        return true;
    }

    return {
        validateCounterOffer,
        invalidCounterOfferMessage,
    }
}

export function useFetchNegotiationHistory({ vehicleListingId, negotiationHistory }: { 
    vehicleListingId: number ,
    negotiationHistory?: Negotiation[],
}): { 
    negotiationHistory: Ref<Negotiation[] | undefined>, 
    loadingNegotiationHistory: Ref<boolean> 
} {
    if (negotiationHistory) {
        return {
            negotiationHistory: ref(negotiationHistory),
            loadingNegotiationHistory:  ref(false),
        }
    }
    const { data, loading } = useFetch<Negotiation[]>(`/vehicles/${vehicleListingId}/getNegotiationHistory`, {
        onError: (error) => {
            openErrorDialog({
                title: `Failed to fetch negotiation data`,
                message: `We encountered an error while getting negotiation data for vehicle listing ${vehicleListingId}`,
                error,
            });
        }
    });
    
    return {
        negotiationHistory: data,
        loadingNegotiationHistory: loading,
    }
}

export function useNegotiationModal({ vehicleListingId, negotiatingUser, buyerOfferAmount, sellerOfferAmount, buyerPersonId, sellerPersonId, submitNegotiationCallback, context }: {
    vehicleListingId: number,
    negotiatingUser: 'buyer' | 'seller',
    buyerOfferAmount: number,
    sellerOfferAmount: number,
    buyerPersonId: number,
    sellerPersonId: number,
    submitNegotiationCallback: (negotiation: SubmitNegotiationDTO) => Promise<void>,
    context: SetupContext<('close' | 'submitted' | any)[]>,
}) {
    const otherUser = negotiatingUser == 'buyer' ? 'seller' : 'buyer';
    const offererPersonId = negotiatingUser == 'buyer' ? buyerPersonId : sellerPersonId;
    const sendNotifications: Ref<boolean> = ref(false);

    const buyerOfferAmountUpdated: Ref<number> = ref(buyerOfferAmount);
    const sellerOfferAmountUpdated: Ref<number> = ref(sellerOfferAmount);

    const negotiatingUserOfferAmount: ComputedRef<number> = computed(() => {
        switch (negotiatingUser) {
            case 'buyer':
                return buyerOfferAmountUpdated.value;
            case 'seller':
                return sellerOfferAmountUpdated.value;
        }
    });

    const otherUserOfferAmount: ComputedRef<number> = computed(() => {
        switch(negotiatingUser) {
            case 'seller':
                return buyerOfferAmountUpdated.value;
            case 'buyer':
                return sellerOfferAmountUpdated.value;
        }
    });

    function updateOfferAmount(amount: number, updateUser?: 'buyer' | 'seller') {
        switch (updateUser ?? negotiatingUser) {
            case 'buyer':
                buyerOfferAmountUpdated.value = amount;
                break;
            case 'seller':
                sellerOfferAmountUpdated.value = amount;
                break;
        }
    }

    function toggleLoading(isLoading: boolean) {
        loadingSubmitCounterOffer.value = isLoading;
        loadingAcceptOffer.value = isLoading;
        loadingRejectOffer.value = isLoading;
    }

    const loadingSubmitCounterOffer = ref(false);
    async function submitCounterOffer(amount: number) {
        loadingSubmitCounterOffer.value = true;
        updateOfferAmount(amount);
        return await submitNegotiation({ 
            toastMessage: 'Counter offer sent!',  
            negotiationStatus: 'negotiating',
        });
    }

    const loadingAcceptOffer = ref(false);
    async function acceptNegotiationOffer() {
        loadingAcceptOffer.value = true;
        const buyerOffer = buyerOfferAmountUpdated.value;
        updateOfferAmount(sellerOfferAmountUpdated.value, 'buyer');
        updateOfferAmount(buyerOffer, 'seller');

        buyerOfferAmountUpdated
        submitNegotiation({
            toastMessage: 'Accepted negotiation offer!',
            negotiationStatus: 'accepted',
        });
    }

    const loadingRejectOffer = ref(false);
    async function rejectNegotiationOffer() {
        loadingRejectOffer.value = true;
        submitNegotiation({
            toastMessage: `Your final offer of ${toCurrency(negotiatingUserOfferAmount.value)} has been sent to the ${otherUser}.`,
            negotiationStatus: 'rejected',
        });
    }

    async function submitNegotiation({ toastMessage, negotiationStatus }: { toastMessage: string, negotiationStatus: negotiationgStatus }) {
        await submitNegotiationCallback({
            buyerPersonId,
            sellerPersonId,
            highestBuyerOffer: buyerOfferAmountUpdated.value,
            lowestSellerOffer: sellerOfferAmountUpdated.value,
            negotiatingUser,
            negotiationStatus,
            disableNotifications: !sendNotifications.value,
        }).then(res => {
                openToast('is-success', toastMessage);
                toggleLoading(false);
                context.emit('submitted', {
                    buyerOfferAmount: buyerOfferAmountUpdated.value,
                    sellerOfferAmount: sellerOfferAmountUpdated.value,
                });
                context.emit('close');
            }).catch(error => {
                toggleLoading(false);
                openErrorDialog({
                    title: 'Could not complete negotiation',
                    message: `We encountered an error while updating negotiations for vehicle ${vehicleListingId}. 
                        Update attempted: ${capitalize(negotiationStatus)} 
                        with buyer offer of ${toCurrency(buyerOfferAmountUpdated.value)}
                        and seller offer of ${toCurrency(sellerOfferAmountUpdated.value)}`,
                    error,
                });
            });
    }

    return {
        sendNotifications,
        negotiatingUserOfferAmount,
        otherUser,
        otherUserOfferAmount,
        offererPersonId,
        updateOfferAmount,
        submitCounterOffer,
        acceptNegotiationOffer,
        rejectNegotiationOffer,
        toggleLoading,
        loadingSubmitCounterOffer,
        loadingAcceptOffer,
        loadingRejectOffer,
    }
}

export function useSingleNegotiationDashboardListing(vehicleListingId: number) {
    const { 
        tableData, 
        loadingTableData: loadingNegotiatingListing, 
        getTableData,
        updateTableRowOnRealtimeUpdate: updateListingOnRealtimeUpdate,
    } = useNegotiationDashboardTable();
    
    getTableData(getNegotiationSearchModifiers('vehicleListingId', vehicleListingId));
    const negotiatingListing = computed(() => tableData.value?.[0] ?? {} as AdminNegotiationTable);

    return {
        negotiatingListing,
        tableData,
        loadingNegotiatingListing,
        updateListingOnRealtimeUpdate,
    }
}

export function useNegotiationDashboardTable() {
    // get table data 
    const rdbUpdateKey: Ref<number> = ref(0);
    const tableData: Ref<AdminNegotiationTable[]> = ref([]);
    const loadingTableData: Ref<boolean> = ref(false);
    const cancelToken: Ref<CancelTokenSource | undefined> = ref(undefined);

    function cancelPreviousRequest() {
        if (cancelToken.value) {
            cancelToken.value.cancel('Previous request to negotiations cancelled');
        }
        cancelToken.value = axios.CancelToken.source();
    }

    async function getTableData(modifiers: FilterFieldOptionsSchema={}): Promise<void> {
        cancelPreviousRequest();
        loadingTableData.value = true;
        tableData.value = await getNegotiations(modifiers, {
            onSuccess: (res) => loadingTableData.value = false,
            onError: () => loadingTableData.value = false,
        }, {
            cancelToken: cancelToken.value?.token,
        });
    }

    async function updateTableRow(vehicleListingId: number) {
        let rowIdx = tableData.value.findIndex(row => row.vehicleListingId == vehicleListingId);
        if (rowIdx >= 0) {
            cancelPreviousRequest();
            const modifier = getNegotiationSearchModifiers('vehicleListingId', vehicleListingId);
            await getNegotiations(modifier!, {
                onSuccess: (res) => {
                    if (res?.length) {
                        Vue.set(tableData.value, rowIdx, res[0]);
                        rdbUpdateKey.value++;
                    }
                },
            });
        }
    }

    function updateTableRowOnRealtimeUpdate(vehicleListingId: number, { isInitialSnapshot, oldSnapshotValue, newSnapshotValue }: HighestBidRefSnapshot) {
        if (isInitialSnapshot || !newSnapshotValue) {
            return;
        }

        if (oldSnapshotValue?.note !== newSnapshotValue.note) {
            updateTableRow(vehicleListingId);
        }
    }

    return {
        tableData,
        getTableData,
        loadingTableData,
        updateTableRowOnRealtimeUpdate,
        rdbUpdateKey,
        cancelToken,
    }
}

export function useFetchNegotiationEmployees() {
    const employeeOptions: Ref<CarmigoEmployeeDTO[]> = ref([]);
    const loadingEmployees: Ref<boolean> = ref(false);

    async function getEmployeesForNegotiations() {
        let employeesFromStorage = store.state.negotiatingEmployees;
        if (employeesFromStorage?.length) {
            employeeOptions.value = employeesFromStorage;
            return;
        }
        loadingEmployees.value = true;
        await getNegotiatingEmployees({
            onSuccess: (res) => {
                loadingEmployees.value = false;
                employeeOptions.value = uniqBy(res, 'personId') as unknown as CarmigoEmployeeDTO[];
            }
        });
    }

    return {
        employeeOptions,
        loadingEmployees,
        getEmployeesForNegotiations,
    }
}

export function useAdminNegotiationTableActions({ tableData }: {
    tableData: Ref<AdminNegotiationTable[]>
}) {
    // open Negotiation modal
    function openNegotiationModal(negotiatingUser: 'buyer' | 'seller', { vehicleListingId, vin, highestBuyerOfferAmount, lowestSellerOfferAmount, seller, buyer }: AdminNegotiationTable) {
        openAdminNegotiationModal({
            vehicleListingId,
            buyerOfferAmount: highestBuyerOfferAmount,
            sellerOfferAmount: lowestSellerOfferAmount,
            buyerPersonId: buyer.personId,
            sellerPersonId: seller.personId,
            negotiatingUser,
            vin,
        }, {
            submitted: ({ buyerOfferAmount, sellerOfferAmount }) => {
                const rowIdx = tableData.value.findIndex(row => row.vehicleListingId == vehicleListingId);
                if (rowIdx >= 0) {
                    const row = tableData.value[rowIdx];
                    tableData.value[rowIdx].highestBuyerOfferAmount = buyerOfferAmount;
                    tableData.value[rowIdx].lowestSellerOfferAmount = sellerOfferAmount;
                    tableData.value[rowIdx].mostRecentOfferType = negotiatingUser;
                    tableData.value[rowIdx].percentMmr = calculateBuyerOfferPercentOfMmr(row.mmr, row.highestBuyerOfferAmount);
                }
            }
        });
    }

    return {
        openNegotiationModal,
    }
}

export function useCurrentNegotiationOffer({ currentNegotiation }: {
    currentNegotiation?: CurrentNegotiationDTO,
}={}) {
    const negotiationOffer: Ref<CurrentNegotiationDTO | undefined> = ref(currentNegotiation);
    const loadingNegotiationOffer: Ref<boolean> = ref(false);

    const { cancelToken, cancelPreviousRequest} = useCancelToken();
    async function getUpdatedNegotiationOffer(vehicleListingId: number, config: APIConfig={}) {
        cancelPreviousRequest();
        await getCurrentNegotiationOffer(vehicleListingId, {
            cancelToken: cancelToken.value?.token,
            skipErrorOnCancelRequest: true,
            ...config,
            onSuccess: (res) => {
                loadingNegotiationOffer.value = false;
                negotiationOffer.value = res;
                applyAPIConfigOnSuccess(res, config);
            },
            onError: (error) => {
                loadingNegotiationOffer.value = false;
                applyAPIConfigOnError(error, config);
            },
        });
    }

    return {
        negotiationOffer,
        loadingNegotiationOffer,
        getUpdatedNegotiationOffer,
    }
}

export function useAuctionNegotiateButton({ negotiatingPersonId, negotiationOffer }: {
    negotiatingPersonId?: Ref<number | undefined>, // usually loggedInUser, can be left undefined if user is admin/dsr
    negotiationOffer: Ref<CurrentNegotiationDTO | undefined>,
}) {
    const { isUserAdmin, isUserDsr } = useUserRole();
    
    const offerSenderType: ComputedRef<'buyer' | 'seller' | undefined> = computed(() =>{
        if (negotiationOffer.value?.mostRecentNegotiationTypeId) {
            return NegotiationTypeEnum[negotiationOffer.value.mostRecentNegotiationTypeId] as 'buyer' | 'seller';
        }
        return undefined;
    });

    const offerRecipientType: ComputedRef<'buyer' | 'seller' | undefined> = computed(() => offerSenderType.value == 'seller' ? 'buyer' : 'seller');
    const personIds: ComputedRef<{ offerSender: number | undefined, offerRecipient: number | undefined } | undefined> = computed(() => {
        switch (offerSenderType.value) {
            case 'seller':
                return {
                    offerSender: negotiationOffer.value?.seller?.personId,
                    offerRecipient: negotiationOffer.value?.buyer?.personId,
                }
            case 'buyer':
                return {
                    offerSender: negotiationOffer.value?.buyer?.personId,
                    offerRecipient: negotiationOffer.value?.seller?.personId,
                }
            default:
                undefined;
        }
    }); 

    const negotiatingUser: ComputedRef<'buyer' | 'seller' | undefined> = computed(() => {
        if (!negotiationOffer.value) {
            return undefined;
        }
        if (isUserAdmin.value || isUserDsr.value || (negotiatingPersonId?.value && negotiatingPersonId?.value == personIds.value?.offerRecipient)) {
            return offerRecipientType.value;
        }
        return undefined;
    });

    // ADMIN BUTTON
    const adminButtonLabel = computed(() => {
        if (isUserAdmin.value || isUserDsr.value) {
            return `Negotiate for ${negotiatingUser.value}`;
        }
    });

    const isOfferPending = computed(() => {
        if (isUserAdmin.value || isUserDsr.value) {
            return false;
        }
        if (negotiationOffer.value && negotiatingPersonId?.value) {
            return personIds.value?.offerSender == negotiatingPersonId?.value;
        }
        return true;
    });

    function openNegotiationModal(listing: { vehicleListingId: number, vin?: string }, config: OpenModalConfig={}) {
        if (!negotiationOffer.value) {
            return;
        }
        openModal({
            ...config,
            component: TheNegotiationModalAuction,
            props: {
                ...listing,
                negotiatingUser: negotiatingUser.value,
                sellerOfferAmount: negotiationOffer.value.sellerOfferAmount,
                buyerOfferAmount: negotiationOffer.value.buyerOfferAmount,
                negotiationStatus: negotiationOffer.value.negotiationStatus == 'rejected' ? 'rejected' : 'in progress',
                displayRejectedMessage: true,
                sellerPersonId: negotiationOffer.value.seller.personId,
                buyerPersonId: negotiationOffer.value.buyer.personId,
                ...config.props,
            },
        });
    }

    return {
        openNegotiationModal,
        isOfferPending,
        adminButtonLabel,
        negotiatingUser,
    }
}