
import { computed, defineComponent, onBeforeMount, PropType, Ref, ref } from 'vue';
import { getTitleTrackingLink, getVehicleDeliveryTrackingLink, updateTitleTrackingLink, updateVehicleDeliveryTrackingLink } from '@/api';
import { validateText } from '@/validation';
import { capitalize } from 'lodash';

import Modal from '@/components/Modal.vue';
import AppInputFieldPhoneNumber from '@/components/AppInputFieldPhoneNumber.vue';

export default defineComponent({
    name: 'TheAddTrackingLinkModal',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        trackingType: {
            type: String as PropType<'vehicle' | 'title'>,
            required: true,
        },
    },
    components: {
        Modal,
        AppInputFieldPhoneNumber,
    },
    emits: ['close'],
    setup(props, context) {
        const loadingTrackingLink: Ref<boolean> = ref(true);
        const loadingSubmit: Ref<boolean> = ref(false);
        const trackingLink: Ref<string> = ref('');
        const formIsValid = computed(() => validateText(trackingLink.value));

        onBeforeMount(async() => {
            switch(props.trackingType) {
                case 'vehicle':
                    getVehicleDeliveryTrackingLink(props.vehicleListingId, {
                        onSuccess: (link) => {
                            trackingLink.value = link;
                            loadingTrackingLink.value = false;
                        },
                        onError: () => loadingTrackingLink.value = false,
                    });
                    break;
                case 'title':
                    getTitleTrackingLink(props.vehicleListingId, {
                        onSuccess: (link) => {
                            trackingLink.value = link;
                            loadingTrackingLink.value = false;
                        },
                        onError: () => loadingTrackingLink.value = false,
                    });
            }
        });

        function updateTrackingLink() {
            loadingSubmit.value = true;
            switch (props.trackingType) {
                case 'vehicle':
                    updateVehicleDeliveryTrackingLink({
                        vehicleListingId: props.vehicleListingId,
                        trackingLink: trackingLink.value,
                    }, {
                        onSuccess: () => {
                            loadingSubmit.value = false;
                            context.emit('close');
                        },
                        onError: () => {
                            loadingSubmit.value = false;
                            context.emit('close');
                        },
                    });
                    break;
                case 'title':
                    updateTitleTrackingLink({
                        vehicleListingId: props.vehicleListingId,
                        trackingLink: trackingLink.value,
                    }, {
                        onSuccess: () => {
                            loadingTrackingLink.value = false;
                            context.emit('close');
                        },
                        onError: () => {
                            loadingTrackingLink.value = false;
                            context.emit('close');
                        },
                    });
                    break;
            }
        }

        return {
            loadingTrackingLink,
            loadingSubmit,
            trackingLink,
            formIsValid,
            updateTrackingLink,
            validateText,
            capitalize,
        }
    }
});
