
import { computed, defineComponent, PropType } from 'vue';
import { useIncrementViewCount, useMarketplaceVDP, useSingleMarketplaceListingListener, useUpdateMarketplaceListingDetailsOnListenerUpdate, useUser, useUserRole } from '../composables';
import { SRPListing } from '../types';
import { isVehicleSold } from '../utils';
import { pick } from 'lodash';
import store from '@/vuex';

import AppListingDetails from './AppListingDetails.vue';
import TheCheckoutButton from './Buttons/TheCheckoutButton.vue';
import TheListingDetailsListingStats from './TheListingDetailsListingStats.vue';
import TheListingDetailsMarketplaceOffers from './TheListingDetailsMarketplaceOffers.vue';
import TheMarketplaceListingButtons from './TheMarketplaceListingButtons.vue';
import TheMarketplaceManageOffersButton from './TheMarketplaceManageOffersButton.vue';
import TheOpenArbitrationClaimButton from '../components/TheOpenArbitrationClaimButton.vue';
import ViewOrderSummaryButton from './Buttons/ViewOrderSummaryButton.vue';

export default defineComponent({
    name: 'AppListingDetailsMarketplace',
    props: {
        listing: {
            type: Object as PropType<SRPListing>,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: true,
        }
    },
    components: {
        AppListingDetails,
        TheCheckoutButton,
        TheListingDetailsListingStats,
        TheListingDetailsMarketplaceOffers,
        TheMarketplaceListingButtons,
        TheMarketplaceManageOffersButton,
        TheOpenArbitrationClaimButton,
        ViewOrderSummaryButton,
    },
    setup(props, { emit, attrs }) {
        useIncrementViewCount(props.listing.id);

        const {
            vehiclePhotos,
            loadingVehiclePhotos,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            marketplaceOffers,
            loadingMarketplaceOffers,
            order,
            loadingOrder,
            fetchOrder,
            titleDelivery,
            vehicleDelivery,
            purchasedVehicle,
            arbitrationDetails,
        } = useMarketplaceVDP({ 
            vehicleListingId: props.listing.id, 
            marketplaceListingId: props.marketplaceListingId,
            vehicleDetails: {
                year: props.listing.year.toString(),
                make: props.listing.brand,
                ...pick(props.listing, ['model', 'trim', 'vin']),
            },
        });

        const user = useUser();
        const { isUserAdmin, isUserDsr } = useUserRole(store);
        const isMyListing = computed(() => {
            return props.listing.userProfile.id == parseInt(user.value.profile.id);
        });

        // RDB
        const { listingDetails, isCheckoutOrLater, getUpdatedMarketplaceListingDetails, getUpdatedMarketplaceListingDetailsOnSnapshot } = useUpdateMarketplaceListingDetailsOnListenerUpdate({
            marketplaceListingId: props.marketplaceListingId,
            currentOffer: props.listing.currentOffer,
            vehicleStatus: props.listing.status,
        });

        useSingleMarketplaceListingListener(props.listing.id, (snapshot) => {
            getUpdatedMarketplaceListingDetailsOnSnapshot(snapshot, {
                onStatusChange: (newStatus, oldStatus) => {
                    if (isVehicleSold(newStatus) && !isVehicleSold(oldStatus)) {
                        fetchOrder(data => order.value = data);
                    }
                }
            });
        });

        const showOfferButton = computed(() => listingDetails.value.status == 'InMarketplace' || listingDetails.value.status == 'SecondChance');
        const isCheckoutStatus = computed(() => listingDetails.value.status == 'MarketplaceCheckout' || listingDetails.value.status == 'SecondChanceCheckout');

        const getPriceLabel = computed(() => {
            if (order.value?.vehiclePrice) {
                return 'Sold for';
            }
            if (props.listing.isSecondChance) {
                return 'Reserve Price';
            }
            return 'Floor Price';
        });

        return {
            isUserAdmin,
            isUserDsr,
            isVehicleSold,
            vehiclePhotos,
            loadingVehiclePhotos,
            announcements,
            loadingAnnouncements,
            bookoutOptions,
            loadingBookoutOptions,
            marketplaceOffers,
            loadingMarketplaceOffers,
            order,
            loadingOrder,
            titleDelivery,
            vehicleDelivery,
            getUpdatedMarketplaceListingDetails,
            arbitrationDetails,
            
            listingDetails,
            isCheckoutOrLater,
            purchasedVehicle,
            isMyListing,
            getPriceLabel,
            showOfferButton,
            isCheckoutStatus,
        }
    },
});
