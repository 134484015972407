var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',_vm._b({staticClass:"carmigo-modal",on:{"close":function($event){return _vm.$emit('close')},"confirm":_vm.updateTrackingLink},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Add "+_vm._s(_vm.capitalize(_vm.$props.trackingType))+" Delivery Tracking Link ")]},proxy:true},{key:"body",fn:function(){return [_c('b-field',_vm._b({},'b-field',{
                type: _vm.validateText(_vm.trackingLink) ? 'is-success' : 'is-danger',
                message: _vm.validateText(_vm.trackingLink) ? '' : 'Tracking link is required',
                label: 'Tracking Link',
                labelPosition: 'on-border',
            },false),[_c('b-input',_vm._b({on:{"icon-right-click":function($event){_vm.trackingLink = ''}},model:{value:(_vm.trackingLink),callback:function ($$v) {_vm.trackingLink=$$v},expression:"trackingLink"}},'b-input',{
                    icon: 'link',
                    iconRight: 'close-circle',
                    iconRightClickable: true,
                    placeholder: 'Tracking link',
                    required: true,
                    loading: _vm.loadingTrackingLink,
                },false))],1)]},proxy:true}])},'Modal',{
        showButtons: ['submit', 'cancel'], 
        isLoading: _vm.loadingSubmit,
        disableConfirm: !_vm.trackingLink || !_vm.formIsValid,
        customLoading: true,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }