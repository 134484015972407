import { FilterFieldOptionsSchema, FilterSchema, ModifierOptionsFilterObject } from "@/types";

export function getDealerSellingModifiers(sellerStoreId?: number): ModifierOptionsFilterObject[] {
    if (!sellerStoreId) {
        return [];
    }
    return [
        {
            property: 'store.id',
            comparator: '=',
            values: sellerStoreId,
        },
        {
            property: 'vehicleStatus.value',
            comparator: 'in',
            values: ['Auction', 'InMarketplace', 'Negotiating', 'SecondChance'],
        }
    ]
}

export type VehicleListingModifiers = 'vin' | 'vehicleListingId' | 'employeePersonIds';
export function getModifierSchema(property: VehicleListingModifiers, values?: string | string[] | number | number[]): FilterFieldOptionsSchema | undefined {
    if (!property || !values) {
        return undefined;
    }

    let filters: FilterSchema[] = [];
    switch (property) {
        case 'vin':
            filters.push({
                property: 'vehicle.vin',
                comparator: 'iLike',
                values,
            });
            break;
        case 'vehicleListingId':
            filters.push({
                property: 'vehicleListing.id',
                comparator: '=',
                values,
            });
            break;
        case 'employeePersonIds':
            filters.push({
              property: 'accountManagerOrDsrSearch',
              comparator: '=',
              values,
              customFilterName: 'nestedOr'
            });
            break;
        default: 
            return undefined;
    }

    return { modifiers: { filters }};
}


export function getNegotiationSearchModifiers(property: 'vin' | 'vehicleListingId' | 'personName' | 'storeName' | 'employeePersonIds', values?: string | string[] | number | number[]): FilterFieldOptionsSchema | undefined {
    if (!property || !values) {
        return undefined;
    }

    let filters: FilterSchema[] = [];
    switch(property) {
        case 'vin':
        case 'vehicleListingId':
        case 'employeePersonIds':
            return getModifierSchema(property, values);
        case 'personName':
            filters.push({
                property: 'n/a',
                comparator: '=',
                values,
                customFilterName: 'negotiationsDashboardSellerOrBuyerFullName',
            });
            break;
        case 'storeName':
            filters.push({
                property: 'n/a',
                comparator: '=', 
                values,
                customFilterName: 'negotiationsDashboardSellerOrBuyerStoreName',
            });
            break;
        default: 
            return undefined;
    }
  
    return { modifiers: { filters }};
}

export function getMarketplaceSearchModifiers(property: 'vin' | 'vehicleListingId' | 'personName' | 'storeName' | 'employeePersonIds' | 'isSecondChance', values?: string | string[] | number | number[] | boolean ): FilterFieldOptionsSchema | undefined {
    if (!property || (values === undefined)) {
        return undefined;
    }
    let filters: FilterSchema[] = [];
    switch(property) {
        case 'vin':
        case 'vehicleListingId':
        case 'employeePersonIds':
            return getModifierSchema(property, values as string | string[] | number | number[]);
        case 'personName':
            filters.push({
                property: 'n/a',
                comparator: '=',
                values,
                customFilterName: 'marketplaceDashboardSellerOrBuyerFullName',
            });
            break;
        case 'storeName':
            filters.push({
                property: 'n/a',
                comparator: '=', 
                values,
                customFilterName: 'marketplaceDashboardSellerOrBuyerStoreName',
            });
            break;
        case 'isSecondChance':
            filters.push({
                property: 'marketplaceDashboardView.isSecondChance',
                comparator: '=',
                values,
            });
            break;
        default: 
            return undefined;
    }
    return { modifiers: { filters }};
}