import { APIConfig, BuyerMarketplaceOffer, FilterFieldOptionsSchema, HighestMarketplaceOffer, VehicleListingAnnouncement } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, openErrorDialog, openToast } from "@/utils";
import { GET, POST, PUT } from ".";
import { MarketplaceOffererTypeEnum } from "@/enums";

export async function getMarketplaceListingId(vehicleListingId: number, config: APIConfig={}): Promise<number | undefined> {
    return await GET(`/marketplace/${vehicleListingId}/marketplaceListingId`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getSecondChanceDetails(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/marketplace/${vehicleListingId}/secondChance`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch Second Chance details',
                message: `We encountered an error while fetching the Second Price details for listing ${vehicleListingId}.`,
                error,
            });
        });
}

export async function getSecondChanceListingForBuyerDashboard(marketplaceListingId: number, config: APIConfig={}): Promise<BuyerMarketplaceOffer | null> {
    return await GET(`/marketplace/getSecondChanceListing/buyerDashboard/${marketplaceListingId}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getSendToSecondChancePricing(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/marketplace/${vehicleListingId}/pricingForSecondChance`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function updateMarketplaceListing(payload: {
    vehicleListingId: number,
    reservePrice?: number,
    buyItNowPrice?: number,
    canBuyItNow?: boolean,
    announcements?: VehicleListingAnnouncement[],
}, config: APIConfig={}) {
    return await PUT(`/marketplace/editListing`, {
        vehicleListingId: payload.vehicleListingId,
        pricingUpdated: {
            reservePrice: payload.reservePrice,
            buyItNowPrice: payload.buyItNowPrice,
            canBuyItNow: payload.canBuyItNow,
        },
        announcementsUpdated: payload.announcements,
    }).then(res => {
        applyAPIConfigOnSuccess(res.data, config);
        return res.data;
    }).catch(error => {
        applyAPIConfigOnError(error, config);
    });
}

export async function acceptMarketplaceOffer(payload: { marketplaceOfferId: number, negotiatingUser: 'seller' | 'buyer' }, config: APIConfig={}) {
    return await PUT(`/marketplace/acceptMarketplaceOfferAmount`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to accept marketplace offer',
                message: `We encountered an error while accepting offer ${payload.marketplaceOfferId} for the ${payload.negotiatingUser}`, 
                error,
            });
        });
}

export async function rejectMarketplaceOffer(payload: {
    marketplaceListingId: number,
    marketplaceOfferId: number,
    marketplaceOffererTypeId: MarketplaceOffererTypeEnum,
}, config: APIConfig={}) {
    return await POST(`/marketplace/rejectMarketplaceOfferAmount`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to reject marketplace offer',
                message: `We encountered an error while rejecting marketplace offer ${payload.marketplaceOfferId} on behalf of the ${MarketplaceOffererTypeEnum[payload.marketplaceOffererTypeId]} for marketplace listing ${payload.marketplaceListingId}`, 
                error,
            });
        });
}

export async function submitMarketplaceCounterOffer(payload: {
    marketplaceListingId: number,
    marketplaceOfferId: number,
    marketplaceOffererTypeId: MarketplaceOffererTypeEnum,
    offererPersonId?: number,
    storeId: number,
    offerAmount: {
        amount: number,
        message?: string,
    }
}, config: APIConfig={}) {
    return await POST(`/marketplace/createMarketplaceOfferAmount`, payload)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to submit counter offer',
                message: `We encountered an error while submitting a ${payload.marketplaceOffererTypeId}'s counter offer of ${payload.offerAmount?.amount} for marketplace listing offer ${payload.marketplaceOfferId}`,
                error, 
            });
        });
}

export async function getMarketplaceHighestOffer(vehicleListingId: number, config: APIConfig={}): Promise<HighestMarketplaceOffer> {
    return await GET(`/marketplace/${vehicleListingId}/highestOffer`, undefined, { cancelToken: config?.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getAdminMarketplaceDashboardListings(modifiers: FilterFieldOptionsSchema, config: APIConfig={}) {
    return await PUT(`/marketplace/dashboard`, modifiers,{ cancelToken: config?.cancelToken })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            let useDefaultErrorHandling = applyAPIConfigOnError(error, config);
            if (!useDefaultErrorHandling) {
                return;
            }
            const celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog({
                title: 'Failed to fetch marketplace offers',
                message: celebrateValidationMessage ?? `We encountered an error while fetching listings with marketplace offers`,
                error,
            });
        });
}

export async function getMarketplaceAutocompleteOptionsForSellerOrBuyerName(searchValue: string, isSecondChance: boolean, config: APIConfig={}) {
    return await PUT(`/marketplace/dashboard/autoComplete/SellerOrBuyerName`, { fullName: searchValue, isSecondChance })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error);
            openToast('is-danger', `We couldn't fetch autocomplete results for name: '${searchValue}'`);
        });
}

export async function getMarketplaceAutocompleteOptionsForStoreName(searchValue: string, isSecondChance: boolean, config: APIConfig={}) {
    return await PUT(`/marketplace/dashboard/autoComplete/SellerOrBuyerStoreName`, { storeName: searchValue, isSecondChance })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error);
            openToast('is-danger', `We couldn't fetch autocomplete results for store name: '${searchValue}'`);
        });
}

export async function getHighestMarketplaceOffererDetails(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/marketplace/${vehicleListingId}/highestOfferer`)
    .then(res => {
        applyAPIConfigOnSuccess(res.data, config);
        return res.data;
    }).catch(error => {
        applyAPIConfigOnError(error, config);
        openErrorDialog({
            title: 'Failed to fetch highest offerer details',
            message: `We encountered an error while fetching highest offerer details for listing ${vehicleListingId}`,
            error,
        });
    });
}