<template>
    <div 
        v-if="!editingField && clickToEdit"
        @click="toggleEditField()"
        class="width-100 flex-row justify-space-between"
    >
        {{ value }}

        <b-button 
            v-if="canDelete && showDeleteWhileNotEditing"
            @click="$emit('delete', updatedValue)"
            class="icon-button ml-1"
            :disabled="disableDelete"
        >
            <b-icon 
                :icon="isDeleted ? 'delete-off-outline' : 'delete'" 
                :class="disableDelete ? 'has-text-grey' : 'has-text-danger'" 
            />
        </b-button>
    </div>
    <div 
        v-else
        class="width-100" 
        :class="{ 
            'is-edited': isEdited,
            'flex-row align-center': canDelete,
            'is-deleted': canDelete && isDeleted,
            'is-missing': isMissing
        }"
    >
        <slot name="inputField" v-bind:value="updatedValue">
            <b-field 
                label-position="on-border" 
                :label="showLabel ? label : ''" 
                :type="$attrs.type"
                :message="$attrs.message"
                class="width-100"
            >
                <b-input 
                    v-if="inputType == 'text'"
                    v-bind="$attrs"
                    v-model="updatedValue"
                    :placeholder="label"
                />
                <b-input 
                    v-else-if="inputType == 'currency'"
                    v-bind="$attrs"
                    v-model="updatedValue"
                    :placeholder="label"
                    v-cleave="masks.numeral"
                />
                <b-autocomplete
                    v-else-if="inputType === 'autocomplete'"
                    v-bind="$attrs"
                    v-model="updatedValue"
                    @typing="$emit('typing', $event)"
                    @select="$emit('select', $event)"
                    @blur="leaveEditModeOnBlur ? toggleEditFieldOnBlur($event) : ''"
                    :placeholder="label"
                />
            </b-field>
        </slot>
        <b-button 
            @click="$emit('delete', updatedValue)"
            class="icon-button ml-1"
            v-if="canDelete"
            :disabled="disableDelete"
        >
            <b-icon 
                :icon="isDeleted ? 'delete-off-outline' : 'delete'" 
                :class="disableDelete ? 'has-text-grey' : 'has-text-danger'" 
            />
        </b-button>
    </div>
</template>

<script>
import { dollarAmountToInt, focusOnElement, makeCamelCase } from '../../utils';
import { getInputMasks } from '../../utils';

export default {
    inheritAttrs: false,
    name: 'EditableField',
    props: {
        label: {
            type: String,
        },
        value: {
            type: [String, Number],
        },
        inputType: {
            type: String,
            default: 'text',
        },
        name: {
            type: String, 
            required: false // uses label if not provided
        },
        clickToEdit: {
            type: Boolean,
            default: false,
        },
        customInputHandler: {
            type: Boolean,
            default: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        isDeleted: {
            type: Boolean,
            default: false,
        },
        isMissing: {
            type: Boolean,
            default: false,
        },
        editingFieldInitial: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        disableDelete: {
            type: Boolean,
            default: false,
        },
        leaveEditModeOnBlur: {
            type: Boolean,
            default: false,
        },
        showDeleteWhileNotEditing: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            updatedValue: this.value,
            editingField: this.editingFieldInitial,
            masks: {},
        }
    },
    computed: {
        isEdited() {
            return this.updatedValue != this.value;
        }    
    },
    beforeMount() {
        this.masks = getInputMasks();
    },
    watch: {
        updatedValue: {
            deep: true,
            handler: function(newValue, oldValue) {
                // don't emit if no oldValue, and no newValue (when converted from string to int)
                if (this.inputType == 'currency' && !oldValue && !dollarAmountToInt(newValue)){
                    return;
                }
                this.emitUpdatedValue(newValue);
            }
        }
    },
    methods: {
        toggleEditFieldOnBlur(event) {
            // prevent leaving editing mode when an autocomplete option is selected
            if (event.relatedTarget?.className == 'dropdown-item') {
                focusOnElement(this.$attrs.id);
                return;
            }

            // If the field is not empty, or clickToEdit is enabled, then allow the field to be toggled.
            if (this.updatedValue !== '' || this.clickToEdit) {
                this.editingField = false;
                this.$emit('toggleEditField', this.editingField);
            }
        },
        emitUpdatedValue(value) {
            const emitValue = this.customInputHandler 
                ? value 
                : { [ this.name ? this.name : makeCamelCase(this.label) ] : value };

            this.$emit('input', emitValue);
        },
        toggleEditField() {
            this.editingField = true;
            this.$emit('toggleEditField', this.editingField);
            window.setTimeout(() => {
                focusOnElement(this.$attrs.id);
            }, 200);
        }
    },
}
</script>
